.o-section {
    &--page-banner {
        padding-right: var(--x-40);
        padding-left: var(--x-40);
        position: relative;
        z-index: 1;
    }

    &--cms-content {
        margin-top: var(--y-65);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &--small-documents {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &--documents {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &--gallery {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &--rubrics {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &--jobs-list {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &--contact-infos {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &--event-infos {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &--next {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &--form {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &--calendar {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);
    }

    &--showcase {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        // padding-right: var(--x-120);
        // padding-left: var(--x-120);
        
        // @media screen and (max-width: 1730px) {
        //     padding-right: var(--x-80);
        //     padding-left: var(--x-80);
        // }
        // @media screen and (max-width: 1500px) {
            padding-right: var(--x-40);
            padding-left: var(--x-40);
        // }
    }

    &--search-results {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        padding-right: var(--x-160);
        padding-left: var(--x-160);

        .c-pagination {
            margin-top: var(--y-65);
        }
    }

    &--events-list {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        // padding-right: var(--x-120);
        // padding-left: var(--x-40);
        
        // @media screen and (max-width: 1730px) {
        //     padding-right: var(--x-80);
        //     padding-left: var(--x-40);
        // }
        // @media screen and (max-width: 1500px) {
            padding-right: var(--x-40);
            padding-left: var(--x-40);
        // }

        .c-event-date-and-reset {
            margin-bottom: var(--y-25);
        }

        .c-pagination {
            margin-top: var(--y-80);
        }
    }

    &--featured-news-list {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        // padding-right: var(--x-120);
        // padding-left: var(--x-120);
        
        // @media screen and (max-width: 1730px) {
        //     padding-right: var(--x-80);
        //     padding-left: var(--x-80);
        // }
        // @media screen and (max-width: 1500px) {
            padding-right: var(--x-40);
            padding-left: var(--x-40);
        // }
    }

    &--news-list {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        // padding-right: var(--x-120);
        // padding-left: var(--x-40);
        
        // @media screen and (max-width: 1730px) {
        //     padding-right: var(--x-80);
        //     padding-left: var(--x-40);
        // }
        // @media screen and (max-width: 1500px) {
            padding-right: var(--x-40);
            padding-left: var(--x-40);
        // }

        .js-scrollfire:not(:last-child) .c-featured-news-list {
            margin-bottom: var(--y-80);
        }
        
        .c-pagination {
            margin-top: var(--y-80);
        }
    }

    &--similar {
        margin-top: var(--y-100);
        margin-bottom: var(--y-100);
        // padding-right: var(--x-120);
        // padding-left: var(--x-120);
        
        // @media screen and (max-width: 1730px) {
        //     padding-right: var(--x-80);
        //     padding-left: var(--x-80);
        // }
        // @media screen and (max-width: 1500px) {
            padding-right: var(--x-40);
            padding-left: var(--x-40);
        // }
    }

    &--home-banner {
        margin-bottom: var(--y-40);
        padding-right: var(--x-40);
        padding-left: var(--x-40);

        @media screen and (max-width: 768px) { margin-bottom: 15px; }
    }
    
    &--featured {
        margin-bottom: var(--y-40);
        padding-right: var(--x-40);
        padding-left: var(--x-40);
        
        @media screen and (max-width: 768px) { margin-bottom: 15px; }
    }

    // &--home {
    //     padding-right: var(--x-40);
    //     padding-left: var(--x-40);
    // }

    @media screen and (max-width: 500px) { padding-right: 15px; padding-left: 15px; }
}