.c-overlay-dropdown {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 19;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 1000ms;
        z-index: -1;
    }

    &__close-button-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: calc(100% + 18px);
        left: 0;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: calc(100% + 18px);
            height: 50px;
            background: linear-gradient(180deg, rgba(var(--rgb-light-purple), 0) 10%, rgb(var(--rgb-light-purple)) 100%);
            pointer-events: none;
            z-index: 2;
        }
    }

    &__close-button-container-2 {
        opacity: 0;
        transition: opacity 500ms;
    }

    &__container {
        width: 100%;
        max-height: calc((var(--vh, 1vh) * 100));
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        background-color: rgb(var(--rgb-light-purple));
        transition-property: top, opacity;
        transition-duration: var(--alerts-transition-duration), 1000ms;
        transition-timing-function: var(--alerts-transition-timing-function), ease;
    }

    &__sections {
        height: min((var(--vh, 1vh) * 100) - 100px, var(--overlay-dropdown-height));
        opacity: 0;
        transform: translateY(100px);
        overflow: hidden;
        position: relative;
        transition-property: height, opacity, transform;
        transition-duration: 1000ms;
        transition-timing-function: var(--in-out-quart), var(--out-quart), var(--out-quart);
        //transition-delay: 0ms, 200ms, 200ms;
    }

    &__section {
        width: 100%;
        padding-top: var(--header-height);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition-property: padding, opacity;
        transition-duration: var(--alerts-transition-duration), 700ms;
        transition-timing-function: var(--alerts-transition-timing-function) var(--out-quart);
    }

    &__scroll {
        max-height: calc((var(--vh, 1vh) * 100) - 100px - var(--header-height));
        padding: var(--y-50) var(--x-120) var(--y-70);
        overflow: auto;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: transparent;
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(98, 98, 98);
            border-radius: 10px;
        }

        @media screen and (max-width: 1150px) {
            padding-right: var(--x-40);
            padding-left: var(--x-40);
        }
    }

    &__content {
        display: grid;
        grid-template: 1fr / repeat(3, 1fr);
        gap: var(--x-80);
    }

    &__title {
        display: block;
        padding: 14px 20px;
        border: 1px solid rgba(var(--rgb-dark-green), 0.2);
        border-radius: 10px;
        font-family: var(--ff);
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        text-transform: uppercase;
        letter-spacing: 1.2px;
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-green));
        }
    }

    &__links-container {
        margin-top: var(--y-25);
        padding-bottom: var(--y-40);
        border-bottom: 1px solid rgba(var(--rgb-dark-green), 0.2);
    }

    &__link-container {
        padding: 0 21px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__link {
        font-family: var(--ff);
        font-size: var(--fz-17);
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-green));
        }
    }
}

.show-overlay-dropdown {
    .c-overlay-dropdown {
        pointer-events: all;
        visibility: visible;
    }
}

.show-overlay-dropdown:not(.closing-overlay-dropdown) {
    --alerts-height: 0px;

    .c-overlay-dropdown {
        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }

        &__close-button-container-2 {
            opacity: 1;
            transition-delay: 1000ms;
        }

        &__container {
            opacity: 1;
        }

        &__sections {
            opacity: 1;
            transform: none;
            transition-delay: 0ms, 500ms, 500ms;
        }
    }
}

@mixin section($section) {
    .show-overlay-dropdown.opening-overlay-dropdown[data-section="#{$section}"] {
        .c-overlay-dropdown {
            &__section[data-section="#{$section}"] {
                transition-delay: 0ms;
            }
        }
    }

    .show-overlay-dropdown[data-section="#{$section}"]:not(.closing-overlay-dropdown) [data-section="#{$section}"] {
        &::before {
            height: 6px;
        }

        .c-header__primary-link-text {
            color: rgb(var(--rgb-green));
        }

        .c-header__primary-link-svg {
            transform: rotate(-90deg);
        }
    }

    .show-overlay-dropdown[data-section="#{$section}"] {
        .c-overlay-dropdown {
            .c-overlay-dropdown__section[data-section="#{$section}"] {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

@include section(municipalite);
@include section(services);
@include section(loisirs-et-culture);
