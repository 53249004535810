.c-form {
    --form-grid-item-length: span 2;
}

.c-form {
    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px 80px;

        @media screen and (max-width: 1400px) { gap: 50px 60px; }
        @media screen and (max-width: 1200px) { gap: 50px 40px; }
        @media screen and (max-width: 1024px) { gap: 50px 30px; }
        @media screen and (max-width: 900px)  { gap: 50px 0px;  }
    }

    &__grid-item {
        grid-column: var(--form-grid-item-length);
        min-width: 0;

        &--half {
            @media screen and (min-width: 901px) { --form-grid-item-length: span 1; }
        }

        &--break + & {
            @media screen and (min-width: 901px) { grid-column-start: 1; }
        }

        &--less-top-spacing {
            @media screen and (max-width: 900px) { margin-top: -30px; }
        }
    }

    .c-form-note {
        margin-top: 8px;
        font-family: var(--ff);
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 1.7em;
        color: rgba(var(--rgb-dark-green), 0.6);
    }

    .c-form-error {
        margin-top: 8px;
        position: absolute;
        top: 100%;
        left: 0;
        font-family: var(--ff);
        font-size: 1.4rem;
        font-weight: 600;
        color: rgb(var(--rgb-red));
    }

    .c-title-field {
        margin-bottom: -20px; // Mettre un peu moins de marge sous les titres dans les forms

        &__heading {
             font-family: var(--ff);
            font-size: var(--fz-25);
            font-weight: 700;
            line-height: 1.4em;
            color: rgb(var(--rgb-black));
        }
    }

    .c-subtitle-field {
        margin-bottom: -20px; // Mettre un peu moins de marge sous les titres dans les forms

        &__heading {
            font-family: var(--ff);
            font-size: var(--fz-21);
            font-weight: 700;
            line-height: 1.2em;
            color: rgb(var(--rgb-3));
        }
    }
}
