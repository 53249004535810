@use "../objects/svg-in-circle-in-link";

.c-header {
    display: flex;
    align-items: center;
    height: var(--header-height);
    padding: 0 var(--x-40);
    background-color: rgb(var(--rgb-white));

    @media screen and (min-width: 991px) {
        position: relative;
        z-index: 52;
    }
    @media screen and (max-width: 500px) { padding-right: 15px; padding-left: 15px; }

    &__grid {
        display: grid;
        grid-template-columns: 180px 1fr 230px;
        grid-template-rows: 56px 1px 77px;
        width: 100%;

        @media screen and (max-width: 990px) {
            grid-template-columns: 68px 1fr 68px;
            grid-template-rows: 1fr;
        }
    }

    &__grid-item {
        &--logo {
            grid-column: 1 / 2;
            grid-row: 1 / 4;
            align-self: center;

            @media screen and (max-width: 990px) {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                justify-self: center;
            }
        }
        &--secondary-links {
            grid-column: 1 / 4;
            grid-row: 1 / 2;
            justify-self: center;
            align-self: center;

            @media screen and (max-width: 990px) { display: none; }
        }
        &--line {
            grid-column: 2 / 4;
            grid-row: 2 / 3;

            @media screen and (max-width: 990px) { display: none; }
        }
        &--primary-links {
            grid-column: 1 / 4;
            grid-row: 3 / 4;
            align-self: center;
            justify-self: center;
            height: 100%;

            @media screen and (max-width: 990px) { display: none; }
        }
        &--facebook {
            grid-column: 3 / 4;
            grid-row: 1 / 2;
            align-self: center;
            justify-self: end;

            @media screen and (max-width: 990px) { display: none; }
        }
        &--search {
            grid-column: 3 / 4;
            grid-row: 3 / 4;
            justify-self: end;
            align-self: center;

            @media screen and (max-width: 990px) {
                grid-column: 3 / 4;
                grid-row: 1 / 2;
            }
        }
        &--mobile-menu {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            align-self: center;
            justify-self: center;
            position: relative;
            z-index: 30;

            @media screen and (min-width: 991px) { display: none; }
        }
    }

    &__logo-img {
        width: 134px;
        height: 91px;

        @media screen and (max-width: 990px) {
            width: 100px;
            height: 71px;
        }
    }

    &__secondary-links {
        :not(:last-child) {
            margin-right: 22px;
        }
    }

    &__secondary-link {
        font-family: var(--ff);
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        text-transform: uppercase;
        letter-spacing: 0.1em;
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-green));
        }
    }

    &__line {
        height: 1px;
        background-color: rgba(var(--rgb-dark-green), 0.2);
    }

    &__primary-links {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__primary-link-container {
        height: 100%;

        &:not(:last-child) {
            margin-right: var(--x-40);
        }
    }

    &__primary-link {
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;

        &::before {
            content: "";
            height: 0px;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            background-color: rgb(var(--rgb-light-purple));
            transition: height 300ms;
        }
    }

    &__primary-link-text {
        font-family: var(--ff);
        font-size: var(--fz-17);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;
    }

    &__primary-link:hover &__primary-link-text {
        color: rgb(var(--rgb-green));
    }

    &__primary-link-svg {
        width: 10px;
        height: 10px;
        stroke: rgb(var(--rgb-green));
        stroke-width: 2px;
        margin-left: 10px;
        transform: rotate(90deg);
        transition: transform 300ms;
    }

    &__facebook-link {
        @extend .o-svg-in-circle-in-link;
        width: 31px;
        height: 31px;

        &::before {
            border: 1px solid rgba(var(--rgb-dark-green), 0.2);
            box-sizing: border-box;
        }

        &:hover::before {
            border-color: rgb(var(--rgb-green));
            background-color: rgb(var(--rgb-green));
            transition: background-color 300ms, border-color 300ms;
        }
    }

    &__facebook-svg {
        width: 13px;
        height: 13px;
        fill: rgb(var(--rgb-dark-green));
    }

    &__facebook-link:hover &__facebook-svg {
        fill: rgb(var(--rgb-white));
    }

    &__search-button {
        display: flex;
        align-items: center;
    }

    &__search-text {
        font-family: var(--ff);
        font-size: var(--fz-17);
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;

        @media screen and (max-width: 1150px) { display: none; }
    }

    &__search-button:hover &__search-text {
        color: rgb(var(--rgb-green));
    }

    &__search-svg-container {
        @media screen and (min-width: 1151px) {
            margin-left: 14px;
            margin-top: 2px;
        }
        @media screen and (max-width: 1150px) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 31px;
            height: 31px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 1px solid rgba(var(--rgb-dark-green), 0.2);
                border-radius: 31px;
                transition: background-color 300ms, border-color 300ms;
            }
        }
        @media screen and (max-width: 990px) {
            width: 68px;
            height: 42px;
        }
        @media screen and (max-width: 380px) {
            width: 60px;
            height: 38px;
        }
    }

    &__search-button:hover &__search-svg-container {
        &::before {
            border-color: rgb(var(--rgb-green));
            background-color: rgb(var(--rgb-green));
        }
    }

    &__search-svg {
        position: relative;
        width: 18px;
        height: 18px;
        stroke-width: 1.5px;
        stroke: rgb(var(--rgb-dark-green));
        transition: stroke 300ms;

        @media screen and (max-width: 1150px) {
            width: 13px;
            stroke-width: 2px;
            height: 13px;
        }
        @media screen and (max-width: 990px) {
            width: 17px;
            height: 17px;
        }
    }

    &__search-button:hover &__search-svg {
        stroke: rgb(var(--rgb-green));

        @media screen and (max-width: 1150px) { stroke: rgb(var(--rgb-white)); }
    }
}
