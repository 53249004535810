.c-event-date-and-reset {
    display: flex;
    align-items: center;

    &__date {
        margin-right: var(--x-25);
        font-family: var(--ff);
        font-size: var(--fz-25);
        font-weight: 600;
        line-height: 1.4em;
        color: rgb(var(--rgb-dark-green));

        @media screen and (max-width: 500px) { font-size: var(--fz-20); }
    }

    &__button {
        display: flex;
        align-items: center;
    }

    &__button-text {
        font-family: var(--ff);
        font-size: var(--fz-15);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;
    }

    &__button:hover &__button-text {
        color: rgb(var(--rgb-green));
    }
    
    &__button-svg {
        margin-right: 6px;
        width: 18px;
        height: 18px;
        stroke: rgb(var(--rgb-green));
        stroke-width: 2px;
    }
}