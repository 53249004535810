@use "sass:math";

// LES TITRES ----------
// Très Gros titre (h1)
.c-huge-title {
    font-weight: 600;
    line-height: 1.15;
    color: rgb(var(--rgb-white));

    &:not([data-character-count]):not(.u-text-65):not(.u-text-60):not(.u-text-55) {
        font-size: var(--fz-65);
    }

    &:not(.u-text-65):not(.u-text-60):not(.u-text-55) {
        font-size: var(--fz-50);
    }

    &--dark-green {
        color: rgb(var(--rgb-dark-green));
    }
}

// Gros titre (h2)
.c-large-title {
    font-size: var(--fz-40);
    font-weight: 600;
    line-height: 1.25em;
    color: rgb(var(--rgb-dark-green));
}

// Moyen titre (h3)
.c-medium-title {
    font-size: var(--fz-30);
    font-weight: 600;
    line-height: 1.3em;
    color: rgb(var(--rgb-dark-green));
}

// Petit titre (h4)
.c-small-title {
    font-size: var(--fz-25);
    font-weight: 600;
    line-height: 1.4em;
    color: rgb(var(--rgb-dark-green));
}


// LES TEXTES ----------
// Le paragraphe
.c-paragraph {
    font-size: var(--paragraph-font-size);
    line-height: var(--paragraph-line-height);
    color: rgb(var(--rgb-dark-green));
}

// L'introduction
.c-introduction  {
    font-size: var(--fz-25);
    line-height: 1.6em;
    color: rgb(var(--rgb-dark-green));
}

// La note
.c-note {
    font-family: var(--ff);
    font-size: var(--fz-14);
    line-height: 1.7em;
    color: rgba(var(--rgb-dark-green), 0.6);
    white-space: normal;
}

// La citation
.c-quote {
    padding-left: 30px;
    border-left: 10px solid rgb(var(--rgb-orange));

    @media screen and (max-width: 1024px) {
        padding-left: 25px;
        border-width: 8px;
    }
    @media screen and (max-width: 768px)  {
        padding-left: 20px;
        border-width: 6px;
    }
    @media screen and (max-width: 500px)  {
        padding-left: 15px;
        border-width: 4px;
    }

    &, p {
        margin: 0;
        font-family: var(--ff);
        font-size: var(--fz-22);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
    }
}


// LES LISTES ----------
// Liste désordonnée et ordonnée
.c-list {
    li {
        @extend .c-paragraph;

        p {
            margin: 0;
        }

        &:not(:first-child) {
            margin-top: 20px;
        }

        ol,
        ul {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 0;

            li {
                &:not(:first-child) {
                    margin-top: 10px;
                }
            }
        }
    }

    &--unordered {
        --unordered-list-padding: 20px;
        @media screen and (max-width: 500px) { --unordered-list-padding: 15px; }

        & > li {
            width: calc(100% - var(--unordered-list-padding));
            position: relative;
            left: var(--unordered-list-padding);

            &::before {
                content: "";
                width: 6px;
                height: 6px;
                position: absolute;
                top: calc(var(--paragraph-line-height) / 2);
                transform: translate(calc(0px - var(--unordered-list-padding)), -50%);
                background-color: rgb(var(--rgb-green));
                border: 1px solid rgb(var(--rgb-green));
                border-radius: 50%;
                box-sizing: border-box;
            }

            & > ul li {
                &::before {
                    background-color: transparent;
                }

                & > ul li::before {
                    background-color: rgb(var(--rgb-green));
                }
            }
        }
    }

    &--ordered {
        --ordered-list-padding: 50px;
        @media screen and (max-width: 768px) { --ordered-list-padding: 25px; }
        @media screen and (max-width: 500px) { --ordered-list-padding: 20px; }
        list-style: none;
        counter-reset: li;

        & > li {
            width: calc(100% - var(--ordered-list-padding));
            position: relative;
            left: var(--ordered-list-padding);

            &::before {
                content: counter(li) ".";
                counter-increment: li;
                display: inline-block;
                position: absolute;
                transform: translateX(calc(0px - var(--ordered-list-padding)));
                font-weight: 700;
                color: rgb(var(--rgb-green));
            }

            ol > li {
                &::before {
                    content: counter(li, lower-alpha) ".";
                }

                ol > li {
                    @media screen and (min-width: 769px) { padding-left: 40px; }

                    &::before {
                        content: counter(li, lower-roman) ".";
                    }
                }
            }
        }
    }
}


// LES TABLEAUX ET ZONES ----------
// Le tableau
.c-table {
    // Ajouter cette variable si les bordures du tableau sont arrondies
    --border-radius: 8px;
    overflow-x: auto;

    table {
        overflow-x: hidden;
        border-collapse: collapse;
        margin-right: 0 !important;

        tr {
            // Applique les styles pour toutes les cellules
            th,
            td {
                @extend .c-paragraph;

                padding: 12px 25px;
                border: 1px solid rgba(var(--rgb-dark-green), 0.2);

                & > :first-child {
                    margin-top: 0;
                }

                & > *:last-child {
                    margin-bottom: 0 !important;
                }

                @media screen and (max-width: 1024px) { padding: 20px 30px; }
                @media screen and (max-width: 768px)  { padding: 18px 25px; }
                @media screen and (max-width: 500px)  { padding: 16px 20px; }
            }

            // Applique les styles pour les cellules d'en-tête
            th,
            td.table-header {
                background-color: rgb(var(--rgb-dark-green));
                border-top-color: rgb(var(--rgb-dark-green));
                border-bottom-color: rgb(var(--rgb-dark-green));

                font-weight: 600;
                color: rgb(var(--rgb-white));
                text-align: left;

                &:first-child {
                    border-left-color: rgb(var(--rgb-dark-green));
                }

                &:last-child {
                    border-right-color: rgb(var(--rgb-dark-green));
                }

                &:not(:first-child) {
                    border-left-color: rgba(var(--rgb-white), 0.2)
                }

                &:not(:last-child) {
                    border-right-color: rgba(var(--rgb-white), 0.2)
                }
            }

            // Applique la couleur de background pour les cellules d'en-tête secondaire
            td.table-secondary-header {
                background-color: rgb(var(--rgb-light-purple));
            }
        }
    }

    // Ajustement des styles du tableaux avec des bordures arrondies
    &--rounded-borders {
        table {
            border-collapse: separate;
            border-radius: var(--border-radius);

            // Le border-radius de la première cellule dans le thead
            thead tr:first-child th:first-child {
                border-top-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans le thead
            thead tr:first-child th:last-child {
                border-top-right-radius: var(--border-radius);
            }

            // Le border-radius de la première cellule dans la première rangée du tbody
            tbody:first-child tr:first-child th:first-child,
            tbody:first-child tr:first-child td:first-child {
                border-top-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans la première rangée du tbody
            tbody:first-child tr:first-child th:last-child,
            tbody:first-child tr:first-child td:last-child {
                border-top-right-radius: var(--border-radius);
            }

            // Le border-radius de la première cellule dans la dernière rangée du tbody
            tr:last-child th:first-child:not(thead tr:last-child th:first-child),
            tr:last-child td:first-child:not(thead tr:last-child td:first-child) {
                border-bottom-left-radius: var(--border-radius);
            }

            // Le border-radius de la dernière cellule dans la dernière rangée du tbody
            tr:last-child th:last-child:not(thead tr:last-child th:last-child),
            tr:last-child td:last-child:not(thead tr:last-child td:last-child) {
                border-bottom-right-radius: var(--border-radius);
            }

            tr {
                // Ajustement des bordures à cause du border-collapse separate (Bordures horizontales en double)
                th,
                td {
                    &:not(:first-child) {
                        border-left: none;
                    }
                }
            }

            // Ajustement des bordures à cause du border-collapse separate (Bordures verticales en double)
            tr:not(:last-child) th,
            tr:not(:last-child) td {
                border-bottom: none;
            }
        }
    }
}

// Zone d'emphase
.c-emphasis {
    padding: var(--y-45) var(--x-60);
    background-color: rgb(var(--rgb-light-purple));
    border-radius: 8px;

    & > :first-child {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0
    }

    .c-table table td.table-secondary-header {
        background-color: rgb(var(--rgb-white));
    }
}
