.c-pagination {
    &__container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border: 1px solid rgba(var(--rgb-dark-green), 0.2);
        border-radius: 30px;
        margin: 0 auto;
    }

    &__prev,
    &__next {
        position: relative;
        width: 35px;
        height: 35px;
        margin-top: -1px;

        @media screen and (max-width: 500px) { width: 25px; }
        @media screen and (max-width: 400px) { width: 20px; }

        &.--disabled {
            pointer-events: none;
        }
    }

    &__prev {
        margin-left: 6px;
    }

    &__next {
        margin-right: 6px;
    }

    &__prev-svg,
    &__next-svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 14px;
        height: 14px;
        stroke-width: 1.5px;
        stroke: rgb(var(--rgb-dark-green));
        transition: stroke 300ms;
    }

    &__prev-svg {
        transform: translate(-50%, -50%) rotate(180deg);
    }

    &__next-svg {
        transform: translate(-50%, -50%);
    }

    &__prev.--disabled &__prev-svg,
    &__next.--disabled &__next-svg {
        stroke: rgba(var(--rgb-dark-green), 0.6);
    }

    &__prev:hover &__prev-svg,
    &__next:hover &__next-svg {
        stroke: rgb(var(--rgb-green));
    }

    &__pages-list {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    &__pages-list-item:not(:last-child) {
        margin-right: 4px;
    }

    &__page {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        background-color: transparent;
        font-family: var(--ff);
        font-size: var(--fz-15);
        font-weight: 600;
        color: rgba(var(--rgb-dark-green), 0.6);
        border-radius: 50%;
        transition: background-color 300ms, color 300ms;

        @media screen and (max-width: 500px) {
            width: 36px;
            height: 36px;
        }
        @media screen and (max-width: 400px) {
            width: 34px;
            height: 34px;
            font-size: var(--fz-14);
        }
        // @media screen and (max-width: 350px) {
        //     width: 30px;
        //     height: 30px;
        // }

        &.--active {
            color: rgb(var(--rgb-white));
            background-color: rgb(var(--rgb-green));
        }
    }

    &__page:not(.--active):hover {
        color: rgb(var(--rgb-dark-green));
        background-color: rgb(var(--rgb-light-purple));
    }
}
