@use "../../objects/picture";

.c-featured-news-card {
    &__picture-container {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(var(--rgb-dark-green), 0);
            transition: background-color 300ms;
            border-radius: 10px;
            z-index: 1;
        }
    }

    &:hover &__picture-container::before {
        background-color: rgba(var(--rgb-dark-green), 0.2);
    }

    &__picture {
        @extend .o-picture;
        padding-top: min(53%, 350px);
        min-height: 200px;
        height: 100%;

        @media screen and (max-width: 768px) { padding-top: min(53%, 300px); }
    }

    &__img {
        border-radius: 10px;
    }

    &__svg-container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        position: absolute;
        top: 19px;
        left: 18px;
        z-index: 1;
        background-color: rgb(var(--rgb-light-purple));
        border-radius: 10px;
        transition: background-color 300ms;
    }

    &:hover &__svg-container {
        background-color: rgb(var(--rgb-green));
    }

    &__svg {
        width: 24px;
        height: 24px;
        fill: rgb(var(--rgb-dark-green));
        transition: fill 300ms;
    }

    &:hover &__svg {
        fill: rgb(var(--rgb-white));
    }

    &__content {
        margin-top: 20px;
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-30);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-green));
        -webkit-line-clamp: 2;
        line-clamp: 2;
    }

    &__infos  {
        margin-top: 20px;
    }

    &__info {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    &__text {
        font-family: var(--ff);
        font-size: var(--fz-15);
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
    }

    &__info:first-child &__text {
        font-weight: 600;
    }
}
