.c-tag {
    display: inline-flex;
    align-items: center;
    padding: 6px 10px;
    border-radius: 6px;
    background-color: rgb(var(--rgb-orange));

    &__svg {
        width: 12px;
        height: 12px;
        fill: rgb(var(--rgb-white));
        stroke: rgb(var(--rgb-orange));
    }

    &__text {
        margin-left: 5px;
        font-family: var(--ff);
        font-size: var(--fz-14);
        font-weight: 600;
        color: rgb(var(--rgb-white));
    }
}