.c-sections-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: var(--x-30);

    @media screen and (max-width: 768px) { grid-template-columns: 1fr; gap: 15px; }

    &__item {
        grid-column: span 1;

        .c-section-card {
            display: block;
            background-color: rgb(var(--rgb-light-purple));
            border-radius: 10px;
            position: relative;
            padding: var(--y-40) var(--x-40);

            &__title {
                font-family: var(--ff);
                font-size: var(--fz-25);
                font-weight: 500;
                line-height: 1.4em;
                color: rgb(var(--rgb-dark-green));
            }
        }
    }
}
