.c-share {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(var(--rgb-light-purple));
    border-radius: 30px;
    
    &--vertical {
        flex-direction: column;
        padding: 18px 12px;
    }
    
    &--horizontal {
        padding: 14px 24px;
    }

    &--horizontal &__link:not(:last-child) {
        margin-right: 20px;
    }

    &--vertical &__link:not(:last-child) {
        margin-bottom: 20px;
    }

    &__svg {
        width: 18px;
        height: 18px;
        fill: rgb(var(--rgb-dark-green));
        transition: fill 300ms;
    }

    &__link:hover &__svg {
        fill: rgb(var(--rgb-green));
    }
}