@use "../../objects/picture";
@use "../buttons/button";

.c-search-results-card {
    display: flex;
    padding-bottom: var(--y-45);
    border-bottom: 1px solid rgba(var(--rgb-dark-green), 0.2);

    &:not(:last-child) {
        margin-bottom: var(--y-45);
    }

    &__picture-container {
        margin-left: var(--x-40);
        
        @media screen and (max-width: 800px) {
            display: none;
        }
    }
    
    &__picture {
        @extend .o-picture;
        flex-shrink: 0;
        position: relative;
        width: 90px;
        height: 90px;
        overflow: hidden;
    }
    
    &__img {
        border-radius: 10px;
    }
    
    &__content {
        flex: 1;
    }

    &__subtitle {
        display: block;
        margin-bottom: 10px;
        font-family: var(--ff);
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__title {
        flex: 1;
        font-size: var(--fz-30);
        font-weight: 600;
        line-height: 1.4em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;
        margin-right: var(--x-60);

        @media screen and (max-width: 800px) {
            margin-top: 15px;
            margin-right: 0;
        }

        > mark {
            color: rgb(var(--rgb-green));
        }
    }

    &__text {
        font-family: var(--ff);
        font-size: var(--fz-17);
        line-height: 1.6471em;
        color: rgb(var(--rgb-dark-green));
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        margin: var(--y-25) 0 var(--y-30);

        > mark {
            font-weight: 600;
            color: rgb(var(--rgb-green));
        }
    }

    &:hover {
        @include button.hover("style-2");
    }
}