.c-next {
    padding: 45px var(--x-60);
    border-radius: 10px;
    background-color: rgb(var(--rgb-light-purple));

    &__surtitle {
        display: block;
        font-family: var(--ff);
        font-size: var(--fz-14);
        font-weight: 700;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        text-transform: uppercase;
        letter-spacing: 1.4px;
    }

    &__title {
        margin: var(--y-25) 0 var(--y-35);
        font-family: var(--ff);
        font-size: var(--fz-30);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-green));
    }

    &__buttons {
        display: flex;
        align-items: center;

        @media screen and (max-width: 450px) {
            flex-direction: column;
        }
        
        .c-button {
            @media screen and (max-width: 450px) {
                width: 100%;
                justify-content: center;
            }
        }
        
        & > :first-child {
            margin-right: 10px;

            @media screen and (max-width: 450px) {
                margin: 0 0 10px 0;
            }
        }
    }
}