.c-overlay-search {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 60;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 700ms;
        z-index: -1;
    }

    &__container {
        width: 100%;
        padding: 0 var(--x-40);
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-100%);
        opacity: 0;
        transition-property: transform, opacity;
        transition-duration: 700ms;
        transition-timing-function: var(--in-out-quart);
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .c-search-field {
        height: calc(var(--header-height) - 20px);
        padding: 24px var(--x-35);

        @media screen and (max-width: 1024px) {
            padding: 20px var(--x-30);
        }
        @media screen and (max-width: 500px) {
            padding: 15px 18px;
        }
        @media screen and (max-width: 360px) {
            padding: 12px 14px;
        }

        &__x-button {
            width: 40px;

            @media screen and (max-width: 600px) { width: 28px; }
        }
    }
}

.show-overlay-search {
    .c-overlay-search {
        pointer-events: all;
        visibility: visible;
    }
}

.show-overlay-search:not(.closing-overlay-search) {

    .c-overlay-search {
        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }

        &__container {
            opacity: 1;
            transform: translateY(calc(var(--alerts-height) + 10px));
        }
    }
}
