.c-featured-news-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: var(--y-60) var(--x-30);

    @media screen and (max-width: 768px) { grid-template-columns: 1fr; }

    &__item {
        grid-column: span 1;
    }
}