@use "../../abstract/placeholders";

.c-rubric-card {
    position: relative;
    border-bottom: 1px solid rgba(var(--rgb-dark-green), 0.2);
    -webkit-tap-highlight-color: transparent;

    &:first-child {
        border-top: 1px solid rgba(var(--rgb-dark-green), 0.2);
    }

    &__header {
        display: flex;
        align-items: center;
        position: relative;
        min-height: 145px;
        padding: 20px 40px 20px 0;
        cursor: pointer;

        @media screen and (max-width: 1024px) {
            min-height: 120px;
            padding-right: 30px;
        }
        @media screen and (max-width: 768px)  {
            min-height: 100px;
            padding-right: 20px;
        }
        @media screen and (max-width: 600px) { padding-right: 10px; }
        @media screen and (max-width: 500px) { padding-right: 0; }
    }

    &__picture {
        @extend %border-radius;
        flex-shrink: 0;
        width: 90px;
        height: 90px;
        margin-right: 30px;
        overflow: hidden;
        border-radius: 8px;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__text {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
        @media screen and (max-width: 500px)  { padding-right: 20px; }
        @media screen and (max-width: 360px)  { padding-right: 16px; }
    }

    &__surtitle {
        display: block;
        margin-bottom: 6px;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-green));
        text-transform: uppercase;
        letter-spacing: 1.2px;
        transition: color 300ms;
    }

    &__header:hover &__surtitle {
        color: rgb(var(--rgb-green));
    }

    &.js-accordion-opened &__surtitle {
        color: rgb(var(--rgb-green));
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-25);
        font-weight: 600;
        line-height: 1.2em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;

        @media screen and (max-width: 600px) { font-size: 2.2rem; }
    }

    &__header:hover &__title {
        color: rgb(var(--rgb-green));
    }

    &.js-accordion-opened &__title {
        color: rgb(var(--rgb-green));
    }

    &__plus-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        border-radius: 20px;
        border: 1px solid rgba(var(--rgb-dark-green), 0.2);
        transition: border 300ms, background-color 300ms;

        @media screen and (max-width: 800px) { width: 50px; height: 30px; }
        @media screen and (max-width: 400px) { width: 40px; height: 28px; }
    }
    
    &__header:hover &__plus-container {
        background-color: rgb(var(--rgb-green));
        border: 1px solid rgb(var(--rgb-green));
    }

    &.js-accordion-opened &__plus-container {
        background-color: rgb(var(--rgb-green));
        border: 1px solid rgb(var(--rgb-green));
    }

    &__plus {
        width: 12px;
        height: 12px;

        &::before,
        &::after {
            height: 1.5px;
            background-color: rgb(var(--rgb-dark-green));
            opacity: 1;
            transition-property: background-color, opacity;
            transition-duration: 300ms;
        }
    }

    &__header:hover &__plus {
        &::before,
        &::after {
            background-color: rgb(var(--rgb-white));
        }
    }

    &.js-accordion-opened &__plus {
        &::before,
        &::after {
            background-color: rgb(var(--rgb-white));
        }

        &::after {
            opacity: 0;
        }
    }

    &__content {
        height: 0;
        overflow: hidden;
        will-change: height;
    }

    &__transition {
        opacity: 0;
        transform: translateY(100px);
        transition-property: opacity, transform;
        transition-duration: 700ms;
        transition-timing-function: var(--in-out-quart);
    }

    &.js-accordion-opened &__transition {
        opacity: 1;
        transform: none;
        transition-delay: 100ms;
    }

    &__transition > :first-child {
        margin-top: 0;
    }

    &__cms-content,
    &__documents,
    &__gallery,
    &__share {
        padding-bottom: var(--y-55);
    }

    &__share {
        padding-bottom: 25px;
    }

    &:not(.js-accordion-opened) {
        iframe {
            pointer-events: none;
        }
    }
}
