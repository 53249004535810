.c-file-field {
    --file-field-color: rgb(var(--rgb-dark-green));
    --file-field-color-hover: rgb(var(--rgb-green));
    --file-field-background-color: rgb(var(--rgb-light-purple));
}

.c-file-field {
    position: relative;

    &__input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &__label {
        display: block;
        position: relative;
        width: 100%;
        padding: 25px calc(var(--x-40) + 40px) 25px var(--x-40);
        border-radius: 10px;
        background-color: var(--file-field-background-color);
        cursor: pointer;
        transition-property: background-color, color;
        transition-duration: 300ms;
    }

    [data-file]:not([data-file=""]) &__label {
        pointer-events: none;
    }
    
    &__text {
        display: block;
        font-family: var(--ff);
        font-size: var(--fz-16);
        font-weight: 500;
        line-height: 1.5em;
        color: var(--file-field-color);
        word-wrap: break-word;
        overflow: hidden;
        transition: color 300ms;
    }

    &__label:hover &__text {
        color: var(--file-field-color-hover);
    }

    &__svg {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        right: var(--x-40);
        fill: var(--file-field-color);
        transform: translate(0,-50%);
        transition: fill 300ms, opacity 300ms;
    }

    &__label:hover &__svg {
        fill: var(--file-field-color-hover);
    }

    [data-file]:not([data-file=""]) &__svg {
        opacity: 0;
    }

    &__x {
        position: absolute;
        width: 18px;
        height: 18px;
        top: 50%;
        right: var(--x-40);
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        pointer-events: none;
        transform: translate(0, -50%);
        transition: opacity 300ms, visibility 0ms ease 300ms;
    }

    &__x::before,
    &__x::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        top: 50%;
        left: 50%;
        background-color: var(--file-field-color);
        transition: background-color 300ms;
    }

    &__x::before {
        transform: translate(-50%, -50%) rotate(45deg);
    } 

    &__x::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &__x:hover::before,
    &__x:hover::after {
        background-color: var(--file-field-color-hover);
    }

    [data-file]:not([data-file=""]) &__x {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }   
}