.c-similar {
    &__header {
        padding-top: 20px;
        border-top: 1px solid rgba(var(--rgb-dark-green), 0.2);
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-14);
        font-weight: 700;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        text-transform: uppercase;
        letter-spacing: 1.4px;
    }

    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: auto;
        gap: var(--y-60) var(--x-30);
        padding: var(--y-35) 0;
    
        @media screen and (max-width: 768px) { grid-template-columns: 1fr; }
        @media screen and (max-width: 768px) { gap: 15px; }
        
        &--2 {
            grid-template-columns: 2fr 1fr 1fr;
            
            @media screen and (max-width: 1300px) {
                grid-template-columns: 3fr 2fr 2fr;
                gap: var(--y-60) 15px;
            }
            @media screen and (max-width: 1100px) {
                grid-template-columns: 1fr 1fr;
                gap: var(--y-60) var(--x-30);
            }
            @media screen and (max-width: 768px) { gap: 15px; }
            @media screen and (max-width: 600px) { grid-template-columns: 1fr; }
        }
        
        &--3 {
            grid-template-columns: 1fr 1fr 1fr;
            padding-top: 0;
            padding-bottom: 0;
            
            @media screen and (max-width: 1100px) {
                grid-template-columns: 1fr 1fr;
            }
            @media screen and (max-width: 768px) { gap: 15px; }
            @media screen and (max-width: 700px) { grid-template-columns: 1fr; }
        }
    }
    
    &__item {
        grid-column: span 1;
    }
    
    &__list--2 &__item {
        &:first-child {
            @media screen and (max-width: 1100px) {
                grid-column: span 2;
            }
            @media screen and (max-width: 768px) { margin-bottom: 15px;} 
            @media screen and (max-width: 600px) {
                grid-column: span 1;
            }
        }
        &:not(:first-child) {
            @media screen and (max-width: 1100px) {
                grid-column: span 1;
                grid-row: 2 / 3;
            }
            @media screen and (max-width: 600px) {
                grid-row: unset;
            }
        }
    }

    &__list--3 &__item--fast-links {
        @media screen and (max-width: 1100px) { grid-column: span 2; }
        @media screen and (max-width: 700px) { grid-column: span 1; }
    }

    &__fast-link:not(:last-child) {
        margin-bottom: 20px;

        @media screen and (max-width: 1200px) { margin-bottom: 10px; }
    }

    &__footer {
        padding-top: 20px;
        border-top: 1px solid rgba(var(--rgb-dark-green), 0.2);
    }

    &__button-container {
        display: flex;
        justify-content: center;
    }
}