.c-breadcrumb {
    --spacing: 36px;
    --color: rgba(var(--rgb-dark-green));
    padding: 20px var(--x-40);
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--color);
    background-color: rgb(var(--rgb-light-purple));

    &__list {
        display: flex;
        align-items: center;
        height: 20px;
    }

    &__item {
        position: relative;

        &:not(:last-child) {
            padding-right: var(--spacing);

            &::after {
                content: '';
                width: 4px;
                height: 4px;
                position: absolute;
                top: 50%;
                right: calc(var(--spacing) / 2);
                transform: translate(50%, -50%);
                background-color: rgb(var(--rgb-green));
                border-radius: 50%;
            }
        }
    }

    &__item:last-child {
        overflow: hidden;
    }

    &__item:last-child span,
    &__item:last-child a {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__text {
        display: block;
        font-family: var(--ff);
        font-size: 1rem;
        color: var(--color);
        text-transform: uppercase;
        letter-spacing: 0.1em;
        transition: color 0.3s;
        white-space: nowrap;

        @media screen and (max-width: 400px) { font-size: 1.1rem; }
    }
    
    &__text.--link:hover {
        color: rgb(var(--rgb-green));
    }
}