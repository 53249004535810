@use "../../objects/x";

.c-overlay-calendar {
    --c-overlay-calendar-width: 580px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 100;

    @media screen and (max-width: 1400px) {
        --c-overlay-calendar-width: 550px;
    }

    &__background {
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 700ms var(--in-out-quart);
        z-index: -1;
        cursor: default;
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: var(--c-overlay-calendar-width);
        height: calc(var(--vh, 1vh) * 100);
        position: absolute;
        top: 0;
        right: calc(0px - var(--c-overlay-calendar-width));
        left: 100%;
        overflow: hidden;
        background-color: rgb(var(--rgb-white));
        transition-property: right, left, padding-top;
        transition-duration: 1000ms, 1000ms, var(--alerts-transition-duration);
        transition-timing-function: var(--in-out-quart), var(--in-out-quart), var(--alerts-transition-timing-function);

        @media screen and (max-width: 640px) { right: 0px; width: 100%; }
    }

    &__close-button {
        align-self: flex-end;
        display: flex;
        align-items: center;
        padding: var(--y-30) var(--x-30) 0 0;
    }

    &__close-text {
        margin-right: 19px;
        font-family: var(--ff);
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        text-transform: uppercase;
        letter-spacing: 1px;

        @media screen and (max-width: 600px) { margin-right: 10px; }
    }

    &__close-x-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        border: 1px solid rgba(var(--rgb-dark-green), 0.2);
        border-radius: 30px;
        transition: background-color 300ms, border-color 300ms;
    }

    &__close-button:hover &__close-x-container {
        background-color: rgb(var(--rgb-green));
        border: 1px solid rgba(var(--rgb-dark-green), 0);
    }

    &__close-x {
        @extend .o-x;
        width: 14px;
        height: 14px;

        &::before,
        &::after {
            height: 1.5px;
            background-color: rgb(var(--rgb-dark-green));
            transition: background-color 300ms;
        }
    }

    &__close-button:hover &__close-x::before,
    &__close-button:hover &__close-x::after {
        background-color: rgb(var(--rgb-white));
    }

    &__scrollbar-container {
        width: 100%;
        padding-top: calc(var(--c-header-height) + 30px);
    }

    &__scrollbar {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__content-container {
        padding: var(--y-100) var(--x-40) var(--y-80);
        display: flex;
        min-height: 100%;
        align-items: center;

        @media screen and (max-height: 850px) { padding-top: var(--y-60); padding-bottom: var(--y-60); }
        @media screen and (max-height: 750px) { padding-top: var(--y-30); padding-bottom: var(--y-30); }
        @media screen and (max-height: 680px) { padding-top: 15px; padding-bottom: 15px; }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 430px;
        min-height: 100%;
        margin: 0 auto;
        opacity: 0;
        transform: translate3d(100px, 0, 0);
        transition-property: opacity, transform;
        transition-duration: 1000ms;
        transition-timing-function: var(--out-quart);
        transition-delay: 200ms;

        .calendar {
            @media screen and (max-width: 1367px) { max-width: 360px; }
            @media screen and (max-height: 850px) { --calendar-main-day-cell-size: 4.4rem; }
            @media screen and (max-height: 700px), (max-width: 500px) { --calendar-main-day-cell-size: 4rem; }

            .datepicker-cell {
                @media screen and (max-height: 850px) { margin-top: 8px; }
                @media screen and (max-height: 700px), (max-width: 500px) { margin-top: 4px; font-size: 1.5rem; }
            }

            .datepicker-header {
                @media screen and (max-height: 850px) { padding: 20px 0; }
                @media screen and (max-height: 700px), (max-width: 500px) { padding: 16px 0; }
            }

            .dow {
                @media screen and (max-height: 700px), (max-width: 500px) { font-size: 1.5rem; }
            }
        }
    }

    &__reset-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__reset-svg {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        stroke: rgb(var(--rgb-green));
        stroke-width: 2px;
    }

    &__reset-text {
        font-family: var(--ff);
        font-size: var(--fz-15);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;
    }

    &__reset-button:hover &__reset-text {
        color: rgb(var(--rgb-green));
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--y-30) var(--x-30);
        border-top: 1px solid rgba(var(--rgb-dark-green), 0.2);

        @media screen and (max-height: 850px) { padding: 22px var(--x-30); }
        @media screen and (max-height: 750px) { padding: 18px var(--x-30); }
        @media screen and (max-height: 680px) { padding: 16px var(--x-30); }
        @media screen and (max-width: 600px) { flex-wrap: wrap; }
    }

    &__footer-title-item {
        margin-right: var(--x-40);
    }

    &__footer-title {
        font-family: var(--ff);
        font-size: var(--fz-17);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-1));
    }

    &__legend-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px var(--x-25);
    }

    &__info {
        display: flex;
        align-items: center;
    }

    &__info-text {
        margin-left: 8px;
        font-family: var(--ff);
        font-size: var(--fz-14);
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
    }

    &__info-event-dot {
        width: 4px;
        height: 4px;
        background-color: rgb(var(--rgb-green));
        border-radius: 50%;
    }

    &__info-today,
    &__info-selected {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 3px;
        font-family: var(--ff);
        font-size: 1.035rem;
        font-weight: 500;
        line-height: 1.5em;
    }

    &__info-today {
        border: 1px solid rgba(var(--rgb-dark-green), 0.2);
        color: rgb(var(--rgb-dark-green));
    }

    &__info-selected {
        color: rgb(var(--rgb-white));
        background-color: rgb(var(--rgb-green));
    }
}

.show-overlay-calendar {
    .c-overlay-calendar {
        pointer-events: all;
        visibility: visible;
    }
}

.show-overlay-calendar:not(.closing-overlay-calendar) {

    .c-overlay-calendar {
        &__container {
            right: 0%;
            left: calc(100% - var(--c-overlay-calendar-width));

            @media screen and (max-width: 640px) { left: 0%; }
        }

        &__content {
            opacity: 1;
            transform: none;
            transition-delay: 500ms;
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }
}
