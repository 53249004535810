@use "../../objects/picture";

.c-event-card {
    &__picture-container {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(var(--rgb-dark-green), 0);
            transition: background-color 300ms;
            border-radius: 10px;
            z-index: 1;
        }
    }

    &:hover &__picture-container::before {
        background-color: rgba(var(--rgb-dark-green), 0.2);
    }

    &__picture {
        @extend .o-picture;
        padding-top: 53%;
        min-height: 200px;
        height: 100%;

        @media screen and (max-width: 768px) { padding-top: min(53%, 300px); }
    }

    &__img {
        border-radius: 10px;
    }

    &__date {
        display: inline-flex;
        padding: 12px 18px;
        position: absolute;
        top: 19px;
        left: 18px;
        max-width: calc(100% - (18px * 2));
        background-color: rgb(var(--rgb-white));
        border-radius: 10px;
        transition: background-color 300ms;
        z-index: 1;

        @media screen and (max-width: 1100px) { padding: 8px 14px; }
    }

    &:hover &__date {
        background-color: rgb(var(--rgb-green));
    }

    &__date-svg {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        fill: rgb(var(--rgb-dark-green));
        transition: fill 300ms;
    }

    &:hover &__date-svg {
        fill: rgb(var(--rgb-white));
    }

    &__date-text {
        margin-left: 10px;
        font-family: var(--ff);
        font-size: var(--fz-15);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;

        @media screen and (max-width: 400px) { font-size: var(--fz-14); }
    }

    &:hover &__date-text {
        color: rgb(var(--rgb-white));
    }

    &__content {
        margin-top: 20px;
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-30);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-green));
        -webkit-line-clamp: 2;
        line-clamp: 2;
    }

    &__infos  {
        margin-top: 20px;
    }

    &__info {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    &__svg-container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        background-color: rgb(var(--rgb-light-purple));
        border-radius: 10px;
    }

    &__svg {
        width: 18px;
        height: 18px;
        fill: rgb(var(--rgb-dark-green));
    }

    &__text {
        margin-left: 14px;
        font-family: var(--ff);
        font-size: var(--fz-15);
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
    }
}
