.c-tooltip {
    position: relative;
    width: var(--tooltip-dimensions);
    height: var(--tooltip-dimensions);
    border-radius: 50%;

    &::after {
        content: "";
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        background-color: var(--tooltip-background-color);
        border-radius: 50%;
    }

    &:hover::after {
        background-color: var(--tooltip-background-color-hover);
    }

    &__svg {
        position: relative;
        width: 100%;
        height: 100%;
        fill: var(--tooltip-svg-fill);
        stroke: var(--tooltip-svg-stroke);
        z-index: 1;
        transition: fill 300ms, stroke 300ms;
    }
    
    &:hover &__svg {
        fill: var(--tooltip-svg-fill-hover);
        stroke: var(--tooltip-svg-stroke-hover);
    }

    --tooltip-background-color: rgb(var(--rgb-white));
    --tooltip-background-color-hover: rgb(var(--rgb-white));
    --tooltip-svg-fill: rgba(var(--rgb-dark-green), 0.6);
    --tooltip-svg-fill-hover: rgb(var(--rgb-dark-green));
    --tooltip-svg-stroke: rgb(var(--rgb-white));
    --tooltip-svg-stroke-hover: rgb(var(--rgb-white));
    
    &--light {
        --tooltip-background-color: rgb(var(--rgb-white));
        --tooltip-background-color-hover: rgb(var(--rgb-white));
        --tooltip-svg-fill: rgb(var(--rgb-white));
        --tooltip-svg-fill-hover: rgb(var(--rgb-white));
        --tooltip-svg-stroke: rgb(var(--rgb-dark-green));
        --tooltip-svg-stroke-hover: rgb(var(--rgb-green));
    }
}

// Les styles de la bulle
.tippy-box[data-theme~='default'],
.tippy-box[data-theme~='info-field'] {
    font-family: var(--ff);
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 10px;
    background-color: rgb(var(--rgb-white));
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    color: rgb(var(--rgb-dark-green));

    &[data-placement^='top'] > .tippy-arrow::before {
        border-top-color: rgb(var(--rgb-white));
    }

    &[data-placement^='right'] > .tippy-arrow::before {
        border-right-color: rgb(var(--rgb-white));
    }

    &[data-placement^='bottom'] > .tippy-arrow::before {
        border-bottom-color: rgb(var(--rgb-white));
    }

    &[data-placement^='left'] > .tippy-arrow::before {
        border-left-color: rgb(var(--rgb-white));
    }

    .tippy-content {
        padding-top: 12px;
        padding-right: 20px;
        padding-bottom: 12px;
        padding-left: 20px;
    }
}
