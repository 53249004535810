.c-input-field {
    --input-field-color: rgb(var(--rgb-dark-green));
    --input-field-placeholder-color: rgba(var(--rgb-dark-green), 0.6);
    --input-field-background: rgb(var(--rgb-white));
    --input-field-border: rgba(var(--rgb-dark-green), 0.6);
    --input-field-border-active: rgb(var(--rgb-dark-green));
    --input-field-border-error: rgb(var(--rgb-red));

    --eye-icon-dimensions: 18px;
}

.c-input-field {
    position: relative;

    &__input-container {
        position: relative;
    }

    &__input {
        width: 100%;
        padding: 8px 0;
        margin-bottom: 1px;
        font-family: var(--ff);
        font-size: var(--fz-16);
        line-height: 1.6em;
        color: var(--input-field-color);
        border: 0;
        border-bottom: 1px solid var(--input-field-border);
        border-radius: 0; // Nécéssaire même si 0 pour éviter les coins ronds en iOS
        background-color: var(--input-field-background);
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;

        // Cacher l'icone de calendrier pour les champs "date" et l'icone d'horloge pour les champs "time"
        &[type="time"],
        &[type="date"] {
            &::-webkit-calendar-picker-indicator {
                display: none;
            }

            &:in-range::-webkit-datetime-edit-year-field,
            &:in-range::-webkit-datetime-edit-month-field,
            &:in-range::-webkit-datetime-edit-day-field,
            &:in-range::-webkit-datetime-edit-text {
                color: transparent;
            }
        }
    }

    &__input:focus {
        border-color: var(--input-field-border-active);
        border-width: 2px;
        margin-bottom: 0px; // Pour éviter le "skip" de 1px lorsque le border passe de 1px à 2px au focus
    }

    &__input {
        box-shadow: 0 0 0 100px var(--input-field-background) inset; // Choisir la couleur voulu pour le input (Cache le background bleu de l'autofill)
    }

    &--error &__input {
        border-color: var(--input-field-border-error);
    }

    // Couleur des Placeholders
    ::-webkit-input-placeholder { color: var(--input-field-placeholder-color); }
    ::-moz-placeholder { color: var(--input-field-placeholder-color); }
    :-ms-input-placeholder {  color: var(--input-field-placeholder-color); }
    :-moz-placeholder { color: var(--input-field-placeholder-color); }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        font-size: var(--fz-16) !important;
        border-bottom: 1px solid var(--input-field-border-active) !important;
        -webkit-text-fill-color: var(--input-field-color);
        caret-color: var(--input-field-color);
        margin-bottom: 1px !important;
    }

    :-webkit-autofill::first-line {
        font-family: var(--ff);
        font-size: var(--fz-16);
    }

    &--with-tooltip &__input {
        padding-right: calc(var(--tooltip-dimensions) + 12px);
    }

    &--with-password-visibility &__input {
        padding-right: calc(var(--eye-icon-dimensions) + 12px);
    }

    &--with-tooltip.c-input-field--with-password-visibility &__input {
        padding-right: calc(var(--tooltip-dimensions) + var(--eye-icon-dimensions) + 24px);
    }

    &__tooltip {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
    }

    &__password-visibility {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
    }

    &--with-tooltip.c-input-field--with-password-visibility &__password-visibility {
        right: calc(var(--tooltip-dimensions) + 12px);
    }

    &__password-visibility-input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &__password-visibility-label {
        position: relative;
        display: block;
        width: var(--eye-icon-dimensions);
        height: var(--eye-icon-dimensions);
        pointer-events: all;
        cursor: pointer;
    }

    &__password-visibility-svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: var(--eye-icon-dimensions);
        height: var(--eye-icon-dimensions);
        fill: rgba(var(--rgb-dark-green), 0.6);
        opacity: 0;
        transition: fill 300ms, opacity 300ms;
    }

    &__password-visibility-input:not(:checked) ~ .c-input-field__password-visibility-label .c-input-field__password-visibility-svg--hidden,
    &__password-visibility-input:checked ~ .c-input-field__password-visibility-label .c-input-field__password-visibility-svg--visible {
        opacity: 1;
    }

    &__password-visibility-label:hover .c-input-field__password-visibility-svg {
        fill: rgb(var(--rgb-dark-green));
    }
}
