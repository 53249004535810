.c-fast-link {
    display: flex;
    align-items: center;
    padding: 12px;
    border: 1px solid rgba(var(--rgb-dark-green), 0.2);
    border-radius: 9px;

    &__svg-container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        background-color: rgb(var(--rgb-light-purple));
        transition: background-color 300ms;
        border-radius: 10px;
    }
    
    &:hover &__svg-container {
        background-color: rgb(var(--rgb-green));
    }
    
    &__svg {
        width: 24px;
        height: 24px;
        fill: rgb(var(--rgb-dark-green));
        transition: fill 300ms;
    }

    &:hover &__svg {
        fill: rgb(var(--rgb-white));
    }

    &__title-container {
        margin: 0 22px;
        flex: 1;
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
    }

    &__arrow-container {
        font-size: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        border-radius: 20px;
        border: 1px solid rgba(var(--rgb-dark-green), 0.2);
        opacity: 0;
        transition: opacity 300ms;

        @media screen and (max-width: 800px) { width: 50px; height: 30px; }
        @media screen and (max-width: 400px) { width: 40px; height: 28px; }
    }

    &:hover &__arrow-container {
        opacity: 1;
    }

    &__arrow {
        width: 14px;
        height: 14px;
        stroke: rgb(var(--rgb-dark-green));
        stroke-width: 1.5px;
        transform: rotate(90deg);
    }
}