.c-button {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 16px 30px;
    font-family: var(--ff);
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.3em;
    color: var(--button-color);
    background-color: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    border-radius: 50px;
    text-decoration: none;
    cursor: pointer;
    transition-property: color, background-color, border;
    transition-duration: 300ms;

    @media screen and (max-width: 600px)  {
        font-size: 1.5rem;
        padding: 17px 34px;
    }

    svg {
        width: var(--button-svg-dimension, 14px);
        height: var(--button-svg-dimension, 14px);
        flex-shrink: 0;
        display: inline-block;
    }

    &[target="_blank"] svg {
        --button-svg-dimension: 11px;
    }

    &[href$=".pdf"] svg,
    &[href$=".doc"] svg,
    &[href$=".zip"] svg {
        --button-svg-dimension: 12px;
    }

    &--svg-on-the-left svg {
        margin-right: var(--button-svg-spacing, 10px);
    }

    &--svg-on-the-right svg {
        margin-left: var(--button-svg-spacing, 10px);
    }

    &--svg-stroke svg {
        stroke: var(--button-svg-color);
        stroke-width: var(--stroke-width, 1px);
        fill: none;
        transition: stroke 300ms;
    }

    &--svg-fill svg {
        fill: var(--button-svg-color);
        stroke: none;
        transition: fill 300ms;
    }

    &--style-1 {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-green));
        --button-border-color: rgb(var(--rgb-green));
        --button-svg-color: rgb(var(--rgb-white));

        &.disabled {
            --button-color: rgb(var(--rgb-dark-green));
            --button-background-color: rgb(210, 210, 210);
            --button-border-color: rgb(210, 210, 210);
            --button-svg-color: rgb(var(--rgb-dark-green));

            &:hover {
                --button-color: rgb(var(--rgb-dark-green));
                --button-background-color: rgb(210, 210, 210);
                --button-border-color: rgb(210, 210, 210);
                --button-svg-color: rgb(var(--rgb-dark-green));
                cursor: not-allowed;
            }
        }
    }

    &--style-2 {
        --button-color: rgb(var(--rgb-dark-green));
        --button-background-color: rgb(var(--rgb-light-purple));
        --button-border-color: rgb(var(--rgb-light-purple));
        --button-svg-color: rgb(var(--rgb-dark-green));
    }

    &--style-3 {
        --button-color: rgb(var(--rgb-dark-green));
        --button-background-color: rgb(var(--rgb-white));
        --button-border-color: rgb(var(--rgb-white));
        --button-svg-color: rgb(var(--rgb-dark-green));
    }

    &--style-4 {
        --button-color: rgb(var(--rgb-dark-green));
        --button-background-color: rgb(var(--rgb-light-purple));
        --button-border-color: rgb(var(--rgb-light-purple));
        --button-svg-color: rgb(var(--rgb-dark-green));
    }

    &--style-5 {
        --button-color: rgb(var(--rgb-white));
        --button-background-color: rgb(var(--rgb-green));
        --button-border-color: rgb(var(--rgb-green));
        --button-svg-color: rgb(var(--rgb-white));
    }
}

@mixin hover($type: "default", $hoverItself: "false") {
    $hover: "";

    @if $hoverItself == "true" {
        $hover: ":hover";
    }

    @if $type == "default" {
        .c-button--style-1#{$hover} {
            --button-background-color: rgb(var(--rgb-dark-green));
            --button-border-color: rgb(var(--rgb-dark-green));
        }
    } @else if $type == "style-2" {
        .c-button--style-2#{$hover} {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-dark-green));
            --button-border-color: rgb(var(--rgb-dark-green));
            --button-svg-color: rgb(var(--rgb-white));
        }
    } @else if $type == "style-3" {
        .c-button--style-3#{$hover} {
            --button-color: rgb(var(--rgb-white));
            --button-background-color: rgb(var(--rgb-dark-green));
            --button-border-color: rgb(var(--rgb-dark-green));
            --button-svg-color: rgb(var(--rgb-white));
        }
    } @else if $type == "style-4" {
        .c-button--style-4#{$hover} {
            --button-background-color: rgb(var(--rgb-white));
            --button-border-color: rgb(var(--rgb-white));
        }
    } @else if $type == "style-5" {
        .c-button--style-5#{$hover} {
            --button-color: rgb(var(--rgb-dark-green));
            --button-background-color: rgb(var(--rgb-white));
            --button-border-color: rgb(var(--rgb-white));
            --button-svg-color: rgb(var(--rgb-dark-green));
        }
    }
}

@include hover($type: "default", $hoverItself: "true");
@include hover($type: "style-2", $hoverItself: "true");
@include hover($type: "style-3", $hoverItself: "true");
@include hover($type: "style-4", $hoverItself: "true");
@include hover($type: "style-5", $hoverItself: "true");
