.c-small-document-card {
    display: flex;
    position: relative;
    padding: 25px var(--x-40);
    align-items: center;
    text-decoration: none;
    background-color: rgb(var(--rgb-light-purple));
    border-radius: 8px;
    transition: background-color 300ms;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &__picture {
        flex-shrink: 0;
        width: 70px;
        height: 70px;
        margin-right: 30px;
        border-radius: 10px;
        overflow: hidden;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__content {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1000px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
        @media screen and (max-width: 500px)  { padding-right: 20px; }
        @media screen and (max-width: 360px)  { padding-right: 16px; }
    }

    &__surtitle {
        display: block;
        margin-bottom: 6px;
        font-family: var(--ff);
        font-size: var(--fz-14);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;
    }

    &:hover &__surtitle {
        color: rgb(var(--rgb-green));
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-17);
        font-weight: 500;
        line-height: 1.2em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-green));
    }

    &__svg-container {
        font-size: 0;
    }

    &__svg {
        width: 16px;
        height: 16px;
        fill: rgb(var(--rgb-dark-green));
        transition-property: stroke, fill;
        transition-duration: 300ms;
    }

    &:hover &__svg {
        fill: rgb(var(--rgb-green));
    }
}
