.c-banner-alerts {
    width: 100%;
    height: var(--alerts-height);
    position: relative;
    overflow: hidden;
    z-index: 50;
    transition-property: height;
    transition-duration: var(--alerts-transition-duration);
    transition-timing-function: var(--alerts-transition-timing-function);

    &__flex {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 var(--x-40);
        background-color: rgb(var(--rgb-red));

        @media screen and (max-width: 500px) { padding-right: 15px; padding-left: 15px; }
    }

    &__nav-item,
    &__slider-item,
    &__close-item {
        min-width: 0;
    }

    &__nav-item,
    &__close-item {
        flex: 0 0 170px;

        @media screen and (max-width: 1024px) { flex: 0 0 auto; }
    }

    &__nav-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding-right: var(--x-40);

        @media screen and (max-width: 600px) { display: none; }
    }

    &__nav-item:not(.displayed) {
        padding: 0 var(--x-25);

        @media screen and (max-width: 600px) { display: none; }
    }

    &__warning-svg-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    &__warning-svg {
        position: relative;
        width: 20px;
        height: 20px;
        fill: rgb(var(--rgb-white));
    }

    &__nav-item:not(.displayed) .warning-svg {
        @media screen and (max-width: 600px) { display: block; }
    }

    &__nav {
        display: none;
        align-items: center;
    }

    &__nav-item.displayed &__nav {
        display: flex;
    }

    &__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px !important;
        margin: 0 15px;
        font-family: var(--ff);
        font-size: var(--fz-13);
        font-weight: 700;
        color: rgb(var(--rgb-white)) !important;
        text-align: center;

        @media screen and (max-width: 600px) { display: none; }

        :first-child {
            margin-right: 3px;
        }
        :last-child {
            margin-left: 3px;
        }
    }

    &__arrows {
        display: flex;
        font-size: 0;
    }

    &__prev-link,
    &__next-link {
        display: inline-block;
        transition: opacity 300ms;
    }

    &__prev-link:hover,
    &__next-link:hover {
        opacity: 0.5;
    }

    &__prev-arrow,
    &__next-arrow {
        width: 14px;
        height: 14px;
        stroke: rgb(var(--rgb-white));
        stroke-width: 1.5px;
    }

    &__prev-link {
        margin-right: 6px;
    }

    &__prev-arrow {
        transform: rotate(180deg);

    }

    &__next-arrow {
        // transform: rotate(-90deg);
    }

    &__slider-item {
        height: 100%;
        flex: 1;
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-wrapper {
        height: 100%;
    }

    .swiper-slide {
    }

    &__slide-link-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__slide-link,
    &__slide-text {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 2px 0;
        font-family: var(--ff);
        font-size: var(--fz-13);
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        // overflow: hidden;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        // text-overflow: ellipsis;
        scrollbar-width: none;

        @media screen and (max-width: 500px) { font-size: 1.3rem; }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__slide-date {
        font-weight: 700;
        font-size: var(--fz-14);
    }

    &__dash {
        padding: 0 10px;

        @media screen and (max-width: 600px) { padding: 0 5px; }
    }

    &__close-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        padding-left: var(--x-40);
    }

    &__close-link {
        display: flex;
        align-items: center;
        transition: opacity 300ms;
    }

    &__close-link:hover {
        opacity: 0.6;
    }

    &__close-text {
        position: relative;
        margin-right: 10px;
        margin-bottom: 2px;
        font-family: var(--ff);
        font-size: var(--fz-13);
        font-weight: 700;
        color: rgb(var(--rgb-white));

        @media screen and (max-width: 1024px) { display: none; }
    }

    .x {
        position: relative;
        width: 14px;
        height: 14px;
        will-change: transform;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: rgb(var(--rgb-white));
        }

        &::before {
            width: 14px;
            height: 1px;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            width: 1px;
            height: 14px;
            transform: translate(-50%, -50%) rotate(-135deg);
        }
    }
}

.c-banner-alerts {
    &__flex {
        opacity: 0;
        transform: translateY(-100%);
        transition-property: opacity, transform;
        transition-timing-function: var(--out-quart);
        transition-duration: 1000ms, 1000ms;
        transition-delay: 600ms, 600ms;
    }

    &[data-fire] &__flex {
        opacity: 1;
        transform: none;
    }
}