@use "circle";

.o-svg-in-circle {
    @extend .o-circle;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
        position: relative;
    }
}