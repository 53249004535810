/*
|--------------------------------------------------------------------------
| Les variables de couleur
|--------------------------------------------------------------------------
|
| Déclaration des variables de couleurs du projet
|
*/

:root {
    --rgb-white: 255, 255, 255;
    --rgb-black: 0, 0, 0;
    --rgb-dark-green: 30, 54, 49;
    --rgb-green: 0, 127, 98;
    --rgb-orange: 197, 102, 34;
    --rgb-light-purple: 235, 237, 247;
    --rgb-red: 224, 25, 44;
}
