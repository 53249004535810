@use "../../abstract/placeholders";

.c-job-card {
    position: relative;
    border-bottom: 1px solid rgba(var(--rgb-dark-green), 0.2);
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 145px;
    padding: 20px 40px 20px 0;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        min-height: 120px;
        padding-right: 30px;
    }
    @media screen and (max-width: 768px)  {
        min-height: 100px;
        padding-right: 20px;
    }
    @media screen and (max-width: 600px) { padding-right: 10px; }
    @media screen and (max-width: 500px) { padding-right: 0; }

    &:first-child {
        border-top: 1px solid rgba(var(--rgb-dark-green), 0.2);
    }

    &__picture {
        @extend %border-radius;
        flex-shrink: 0;
        width: 90px;
        height: 90px;
        margin-right: 30px;
        overflow: hidden;
        border-radius: 8px;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__text {
        flex: 1;
        padding-right: 50px;

        @media screen and (max-width: 1024px) { padding-right: 40px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
        @media screen and (max-width: 500px)  { padding-right: 20px; }
        @media screen and (max-width: 360px)  { padding-right: 16px; }
    }

    &__surtitle {
        display: block;
        margin-bottom: 6px;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-green));
        text-transform: uppercase;
        transition: color 300ms;
    }

    &:hover &__surtitle {
        color: rgb(var(--rgb-green));
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-25);
        font-weight: 600;
        line-height: 1.2em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;
    }

    &:hover &__title {
        color: rgb(var(--rgb-green));
    }

    &__svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        border-radius: 20px;
        border: 1px solid rgba(var(--rgb-dark-green), 0.2);
        transition: border 300ms, background-color 300ms;

        @media screen and (max-width: 800px) { width: 50px; height: 30px; }
        @media screen and (max-width: 400px) { width: 40px; height: 28px; }
    }
    
    &:hover &__svg-container {
        background-color: rgb(var(--rgb-green));
        border: 1px solid rgb(var(--rgb-green));
    }

    &__svg {
        width: 14px;
        height: 14px;
        stroke-width: 1.5px;
        stroke: rgb(var(--rgb-dark-green));
        transition: stroke 300ms;
        transform: rotate(90deg);

        @media screen and (max-width: 800px) {
            width: 12px;
            height: 12px;
        }
    }
    
    &:hover &__svg {
        stroke: rgb(var(--rgb-white));
    }
}