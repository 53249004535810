@use "../objects/picture";

.c-home-banner {
    &__container {
        position: relative;
        height: clamp(430px, (var(--vh, 1vh) * 100) - (var(--header-height) + var(--alerts-height) + 10px), 560px);

        @media screen and (max-width: 1370px) { height: clamp(430px, (var(--vh, 1vh) * 100) - (var(--header-height) + var(--alerts-height) + 10px), 500px); }
        @media screen and (max-width: 1200px) { height: clamp(430px, (var(--vh, 1vh) * 100) - (var(--header-height) + var(--alerts-height) + 10px), 430px); }
        @media screen and (max-width: 768px) { height: 100%; }
    }

    &__images-swiper-container {
        position: relative;
        height: 100%;

        .swiper-wrapper,
        .swiper {
            height: 100%;
        }

        .swiper-slide {
            height: 100%;
            &.swiper-slide-active {
                z-index: 10;
            }
            &:not(.swiper-slide-active) {
                transition-delay: 500ms;
            }
        }
    }

    &__tooltip-container {
        position: absolute;
        top: 14px;
        right: 14px;
        z-index: 2;
    }

    &__nav-item {
        position: absolute;
        bottom: var(--y-25);
        left: var(--x-35);
        display: flex;
        align-items: center;
        border-radius: 30px;
        border: 1px solid rgba(var(--rgb-white), 0.3);
        padding: 18px 20px;
        z-index: 2;

        @media screen and (max-width: 500px) {
            bottom: 14px;
            left: 13px;
        }
    }

    &__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px !important;
        margin-right: 18px;
        font-family: var(--ff);
        font-size: var(--fz-13);
        font-weight: 600;
        color: rgb(var(--rgb-white)) !important;
        text-align: center;
    }

    &__arrows {
        display: flex;
        font-size: 0;
    }

    &__prev-link,
    &__next-link {
        display: inline-block;
        transition: opacity 300ms;
    }

    &__prev-link:hover,
    &__next-link:hover {
        opacity: 0.5;
    }

    &__prev-arrow,
    &__next-arrow {
        width: 14px;
        height: 14px;
        stroke: rgb(var(--rgb-white));
        stroke-width: 1.5px;
    }

    &__prev-link {
        margin-right: 14px;
    }

    &__prev-arrow {
        transform: rotate(180deg);
    }

    &__next-arrow {
        // transform: rotate(-90deg);
    }

    &__picture-container {
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        height: 100%;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(0,0,0,0.3) 20%, rgba(255,255,255,0) 70%);
            z-index: 1;
        }
    }
    
    &__picture {
        @extend .o-picture;
        height: min(100%, 560px);

        @media screen and (max-width: 768px) { padding-top: max(41%, 236px); height: auto; }
    }
    
    &__img {
        @media screen and (min-width: 769px) { border-top-left-radius: 10px; }
    }

    &__content-swiper-container {
        width: 430px;
        position: absolute;
        right: var(--x-30);
        bottom: var(--y-30);
        padding: var(--y-40) var(--x-40);
        background-color: rgb(var(--rgb-dark-green));
        z-index: 1;
        border-radius: 10px;

        @media screen and (max-width: 768px) {
            position: static;
            width: 100%;
            margin-top: 15px;
        }

        .swiper-slide {
            &.swiper-slide-active {
                transition-delay: 500ms;
            }
        }
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-32);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    
    &__subtitle {
        display: block;
        margin-top: 14px;
        font-family: var(--ff);
        font-size: var(--fz-20);
        line-height: 1.4em;
        color: rgb(var(--rgb-white));
    }

    &__button-container {
        margin-top: var(--y-45);
    }
}