@use "../objects/svg-in-circle-in-link";

.c-footer {
    padding: var(--y-50) var(--x-40) 20px;
    background-color: rgb(var(--rgb-dark-green));
    overflow: hidden;

    @media screen and (max-width: 500px) { padding-right: 15px; padding-left: 15px; }

    // Grid
    &__grid {
        display: grid;
        grid-template-columns: 127px 1fr 420px;
        grid-template-rows: auto 71px auto;

        @media screen and (max-width: 1600px) { grid-template-columns: 127px 1fr 420px; }
        @media screen and (max-width: 1440px) { grid-template-columns: 127px 1fr 420px; }
        @media screen and (max-width: 1350px) { grid-template-columns: 127px 1fr 420px; }
        @media screen and (max-width: 900px)  { grid-template-columns: 1fr; grid-template-rows: auto auto auto 40px auto; }
    }

    &__grid-item {
        align-self: end;

        &--logo {
            grid-column: 1 / 2;
            grid-row: 1 / 2;

            @media screen and (max-width: 900px) { grid-row: 1 / 2; }
        }
        &--infos {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
        }
        &--buttons {
            grid-column: 3 / 4;
            grid-row: 1 / 2;

            @media screen and (max-width: 900px) { grid-row: 2 / 3; }
        }
        &--line {
            grid-column: 1 / 4;
            grid-row: 2 / 3;

            @media screen and (max-width: 900px) { grid-row: 4 / 5; }
        }
        &--copyright {
            grid-column: 1 / 3;
            grid-row: 3 / 4;

            @media screen and (max-width: 900px) { grid-row: 5 / 6; }
        }
        &--back-to-top {
            grid-column: 3 / 4;
            grid-row: 3 / 4;

            @media screen and (max-width: 900px) { grid-row: 3 / 4; }
        }

        @media screen and (max-width: 900px) { grid-column: 1 / 2; }
    }
    
    // Logo
    &__logo-link {
        display: block;
        margin-bottom: 6px;
        font-size: 0;
    }

    &__logo-img {
        width: 127px;
        height: 90px;
    }

    // Infos
    &__infos {
        display: flex;

        @media screen and (max-width: 1160px) {
            flex-direction: column;
            margin-left: var(--x-120);
        }
        @media screen and (max-width: 1050px) { margin-left: var(--x-40); margin-right: var(--x-40); }
        @media screen and (max-width: 900px) { display: none; }
    }

    &__address {
        margin: 0 var(--x-80);
        font-family: var(--ff);
        font-size: var(--fz-17);
        line-height: 1.5em;
        color: rgb(var(--rgb-white));

        @media screen and (max-width: 1440px) { margin: 0 var(--x-70); }
        @media screen and (max-width: 1350px) { margin: 0 var(--x-40); font-size: var(--fz-15); }
        @media screen and (max-width: 1160px) { margin: 0 0 12px; }
    }
    
    &__phone-link,
    &__email-link {
        font-family: var(--ff);
        font-size: var(--fz-17);
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        transition: color 300ms;

        @media screen and (max-width: 1350px) { font-size: var(--fz-15); }

        &:hover {
            color: rgba(var(--rgb-white), 0.6);
        }
    }

    // Buttons
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .c-button:first-child {
            @media screen and (max-width: 500px) {
                order: 2;
            }
        }

        .c-button:last-child {
            margin-left: 10px;

            @media screen and (max-width: 500px) {
                margin: 0 0 10px 0;
                order: 1;
            }
        }

        @media screen and (max-width: 900px) {
            margin: var(--y-50) 0;
            justify-content: flex-start;
        }
        @media screen and (max-width: 500px) {
            margin: var(--y-50) 0;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    // Line
    &__line {
        height: 1px;
        margin-bottom: 20px;
        background-color: rgba(var(--rgb-white), 0.3);
    }

    // Copyright
    &__copyright-container {
        display: flex;
        align-items: center;
    }

    &__facebook-link {
        @extend .o-svg-in-circle-in-link;
        width: 31px;
        height: 31px;

        &::before {
            border: 1px solid rgba(var(--rgb-white), 0.3);
            box-sizing: border-box;
        }

        &:hover::before {
            border-color: rgb(var(--rgb-green));
            background-color: rgb(var(--rgb-green));
            transition: background-color 300ms, border-color 300ms;
        }
    }

    &__facebook-svg {
        width: 13px;
        height: 13px;
        fill: rgb(var(--rgb-white));
    }

    &__copyright-and-blanko {
        display: flex;
        align-items: center;
        margin-left: var(--x-30);

        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 12px;
        }
    }

    &__copyright {
        font-family: var(--ff);
        font-size: var(--fz-13);
        line-height: 1.5em;
        color: rgb(var(--rgb-white));

        @media screen and (max-width: 500px) {
            font-size: 1.2rem;
        }
    }

    &__blanko-link {
        margin-left: var(--x-30);
        font-family: var(--ff);
        font-size: var(--fz-13);
        font-weight: 700;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        transition: color 300ms;

        @media screen and (max-width: 500px) {
            margin-left: 0;
            font-size: 1.2rem;
        }

        &:hover {
            color: rgba(var(--rgb-white), 0.6);
        }
    }

    // Back to top
    &__back-to-top-button-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media screen and (max-width: 900px) {
            justify-content: flex-start;
        }
        @media screen and (max-width: 900px) {
            margin-top: 20px;
        }
    }

    &__back-to-top-button {
        display: flex;
        align-items: center;
    }

    &__back-to-top-text {
        font-family: var(--ff);
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
        text-transform: uppercase;
        letter-spacing: 0.1em;

        @media screen and (max-width: 900px) {
            order: 2;
        }
    }
    
    &__back-to-top-svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        position: relative;
        margin-left: 16px;
        
        @media screen and (max-width: 900px) {
            order: 1;
            margin-left: 0;
            margin-right: 16px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid rgba(var(--rgb-white), 0.3);
            border-radius: 30px;
            transition: background-color 300ms, border-color 300ms;
        }
    }
    
    &__back-to-top-svg {
        width: 14px;
        height: 14px;
        stroke: rgb(var(--rgb-white));
        position: relative;
    }
    
    &__back-to-top-button:hover &__back-to-top-svg-container::before {
        border-color: rgb(var(--rgb-green));
        background-color: rgb(var(--rgb-green));
    }
}