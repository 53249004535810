/*
|--------------------------------------------------------------------------
| Root
|--------------------------------------------------------------------------
|
| Déclaration de tout ce qui doit se retrouver dans le sélecteur :root.
|
*/

@use "../abstract/mixins" as *;

:root {
    // Générer les variables CSS des font-sizes
    @include fontSizes(
        "13", "14", "15", "16", "17", "20", "21", "22", "25", "28", "30", "32", "40", "50", "55", "60", "65", "70",
    );

    // Générer les variables CSS des espacements
    @include spacings(
        "x-25", "x-30", "x-35", "x-40", "x-45", "x-50", "x-60", "x-70", "x-80", "x-120", "x-160",
        "y-20", "y-25", "y-30", "y-35", "y-40", "y-45", "y-50", "y-55", "y-60", "y-65", "y-70", "y-80", "y-90", "y-100",
    );

    // Variables CSS
    --header-height: 133px;
    @media screen and (max-width: 990px) { --header-height: 103px; }
    --alerts-height: 46px;
    --alerts-transition-duration: 500ms;
    --alerts-transition-timing-function: ease;
    --overlay-dropdown-height: var(--header-height);
    --paragraph-font-size: var(--fz-17);
    --paragraph-line-height: 1.6em;

    --tooltip-dimensions: 18px;
}
