.c-news-card {
    display: block;
    background-color: rgb(var(--rgb-light-purple));
    border-radius: 10px;
    position: relative;
    padding: 20px var(--x-40) var(--y-40);

    &__svg-container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 19px;
        left: 22px;
        width: 60px;
        height: 60px;
        background-color: rgb(var(--rgb-white));
        transition: background-color 300ms;
        border-radius: 10px;
    }
    
    &:hover &__svg-container {
        background-color: rgb(var(--rgb-green));
    }
    
    &__svg {
        width: 24px;
        height: 24px;
        fill: rgb(var(--rgb-dark-green));
        transition: fill 300ms;
    }

    &:hover &__svg {
        fill: rgb(var(--rgb-white));
    }

    &__title-container {
        min-height: 125px;
        padding: calc(var(--y-50) + 60px) 0 var(--y-55);

        @media screen and (max-width: 1700px) { padding: calc(var(--y-30) + 60px) 0 var(--y-55);}
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-25);
        font-weight: 500;
        line-height: 1.4em;
        color: rgb(var(--rgb-dark-green));
    }

    &__date {
        display: block;
        margin-bottom: 5px;
        font-family: var(--ff);
        font-size: var(--fz-15);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
    }

    &__category {
        display: block;
        font-family: var(--ff);
        font-size: var(--fz-15);
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
    }
}