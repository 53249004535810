.o-layout {
    display: flow-root;
    position: relative;

    &__flex {
        display: flex;
        padding-top: var(--y-65);

        &--small-padding-top {
            padding-top: 25px;
        }

        &:last-child {
            margin-bottom: var(--y-100);
        }
    }
    
    &__content-item {
        flex: 1;
        min-width: 0;

        & > :first-child {
            margin-top: 0;
        }

        & > :last-child {
            margin-bottom: 0;
        }
    }

    &__sidebar-item {
        padding: 0 var(--x-40);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        position: relative;

        @media screen and (max-width: 1200px) { padding-left: 0; }
        @media screen and (max-width: 900px) { display: none; }
    }

    &__sidebar {
        position: sticky;
        top: 30px;
    }
}

.scrollfire-child-fade-up > *{
    --scrollfire-opacity: 0;
    --scrollfire-translateY: 50px;
}
.scrollfire-child-fade-up > *{
    opacity: var(--scrollfire-opacity);
    transform: translateY(var(--scrollfire-translateY));
    transition-property: opacity, transform;
    transition-duration: var(--scrollfire-duration);
    transition-timing-function: var(--scrollfire-timing-function);
}
[data-fire].scrollfire-child-fade-up > *{
    --scrollfire-opacity: 1;
    --scrollfire-translateY: 0px;
}
.js-scrollfire-delay-enabled .scrollfire-child-fade-up > *{
    transition-delay: var(--scrollfire-delay);
}