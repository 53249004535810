@use "../../base/typography";

.c-underlined-link {
    @extend .c-paragraph;
    font-size: var(--paragraph-font-size, var(--underlined-link-font-size));
    font-weight: var(--underlined-link-font-weight, 600);
    color: var(--underlined-link-color);
    text-decoration: none;

    span {
        padding-bottom: 2px;
        box-shadow: inset 0 -0.1em 0 0 var(--underlined-link-line-color);
        transition-property: color, box-shadow;
        transition-duration: 300ms;
    }

    svg {
        width: var(--underlined-link-svg-dimension, 0.647em);
        height: var(--underlined-link-svg-dimension, 0.647em);
        flex-shrink: 0;
        display: inline-block;
        vertical-align: var(--underlined-link-svg-vertical-align, baseline);
    }

    &--svg-on-the-left svg {
        margin-right: var(--underlined-link-svg-spacing, 0.1176em);
    }

    &--svg-on-the-right svg {
        margin-left: var(--underlined-link-svg-spacing, 0.1176em);
    }

    &--svg-stroke svg {
        stroke: var(--underlined-link-svg-color);
        stroke-width: var(--stroke-width, 1px);
        fill: none;
        transition: stroke 300ms;
    }

    &--svg-fill svg {
        fill: var(--underlined-link-svg-color);
        stroke: none;
        transition: fill 300ms;
    }

    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"] {
        svg {
            --underlined-link-svg-dimension: 0.7058em;
        }
    }

    &--style-1 {
        --underlined-link-color: rgb(var(--rgb-orange));
        --underlined-link-line-color: rgb(var(--rgb-orange));
        --underlined-link-svg-color: rgb(var(--rgb-orange));
    }
}


@mixin hover($type: "default", $hoverItself: "false") {
    $hover: "";

    @if $hoverItself == "true" {
        $hover: ":hover";
    }

    @if $type == "default" {
        .c-underlined-link--style-1#{$hover} {
            --underlined-link-color: rgb(var(--rgb-dark-green));
            --underlined-link-line-color: rgb(var(--rgb-dark-green));
            --underlined-link-svg-color: rgb(var(--rgb-dark-green));
        }
    }// @else if $type == "style-2" {
    //     .c-underlined-link--style-2#{$hover} {
    //         --underlined-link-line-color: rgba(var(--rgb-white), 0);
    //     }
    // }
}

@include hover($type: "default", $hoverItself: "true");
@include hover($type: "style-2", $hoverItself: "true");
