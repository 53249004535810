@use "buttons/underlined-link";

.c-event-infos {
    &__title {
        font-family: var(--ff);
        font-size: var(--fz-30);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-green));
    }

    &__infos {
        margin: var(--y-20) 0 var(--y-30);
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        gap: 0 15px;

        @media screen and (max-width: 990px) { grid-template-columns: 1fr; }
    }

    &__info {
        grid-column: span 1;
        display: flex;
        align-items: center;
        padding: 16px 0;
        margin-right: var(--x-40);
        border-bottom: 1px solid rgba(var(--rgb-dark-green), 0.2);

        @media screen and (max-width: 990px) { margin-right: 0; }
    }
    
    &__svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        margin-right: 14px;
        border-radius: 10px;
        background-color: rgb(var(--rgb-light-purple));
    }

    &__svg {
        width: 18px;
        height: 18px;
        fill: rgb(var(--rgb-dark-green));
    }

    &__text {
        font-family: var(--ff);
        font-size: var(--fz-15);
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));

        @media screen and (max-width: 360px) { font-size: var(--fz-14); }
    }

    .c-underlined-link {
        @extend .c-underlined-link--svg-fill;
        @extend .c-underlined-link--svg-on-the-right;
        --underlined-link-svg-spacing: 5px;
        --underlined-link-svg-dimension: 12px;
        font-size: var(--fz-15);

        @media screen and (max-width: 360px) { font-size: var(--fz-14); }
    }
}