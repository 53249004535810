@use "../../objects/x";

.c-search-field {
    width: 100%;
    min-width: 0;
    border-radius: 10px;
    background-color: rgb(var(--rgb-white));
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 24px;

    @media screen and (max-width: 1024px) {
        padding: 20px 22px;
    }
    @media screen and (max-width: 500px) {
        padding: 15px 18px;
    }
    @media screen and (max-width: 360px) {
        padding: 12px 14px;
    }

    &__container {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    &__field {
        flex: 1;
        align-self: stretch;
        min-width: 0;
        padding: 0;
        font-family: var(--ff);
        font-size: 2rem;
        line-height: 1.6em;
        color: rgb(var(--rgb-dark-green));
        border: 0;
        border-radius: 0; // Nécéssaire même si 0 pour éviter les coins ronds en iOS
        background-color: rgb(var(--rgb-white));
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;

        @media screen and (max-width: 768px) { font-size: 1.8rem; }
        @media screen and (max-width: 500px) { font-size: 1.6rem; }
    }

    &__x-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        opacity: 0;
        transition: opacity 300ms;
        pointer-events: none;
    }

    &__x-button:hover::before {
        background-color: rgb(var(--rgb-2));
    }

    &__x-button.is-active {
        opacity: 1;
        pointer-events: all;
    }

    &__x {
        @extend .o-x;
        width: 14px;
        height: 14px;
        display: flex;
    }

    &__x::before,
    &__x::after {
        height: 1.5px;
        background-color: rgb(var(--rgb-dark-green));
        transition: background-color 300ms;
    }

    &__x-button:hover &__x::before,
    &__x-button:hover &__x::after {
        background-color: rgb(var(--rgb-green));
    }

    .c-button svg {
        width: 16px;
        height: 16px;
        stroke-width: 1.5px;
    }

    .c-button {
        @media screen and (max-width: 768px) {
            padding: 16px;
            border-radius: 10px;

            svg {
                margin: 0;
            }

            span {
                display: none;
            }
        }
        @media screen and (max-width: 500px) { padding: 10px; }
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        font-family: var(--ff);
        font-size: 2rem !important;
        border-bottom: 0;
        background-color: rgb(var(--rgb-white)) !important;
        -webkit-box-shadow: 0 0 0 50px rgb(var(--rgb-white)) inset !important;
        -webkit-text-fill-color: rgb(var(--rgb-dark-green));
        caret-color: rgb(var(--rgb-dark-green));

        @media screen and (max-width: 768px) { font-size: 1.8rem !important; }
        @media screen and (max-width: 500px) { font-size: 1.6rem !important; }
    }
}
