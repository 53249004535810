@use "svg-in-circle";

.o-svg-in-circle-in-link {
    @extend .o-svg-in-circle;
    
    &::before {
        transition-property: background-color, transform;
        transition-duration: 300ms;
        transition-timing-function: ease, var(--out-cubic);
    }
    
    svg {
        transition-property: fill, stroke;
        transition-duration: 300ms;
    }
}