@use "../objects/picture";

.c-page-banner {
    display: flex;
    justify-content: stretch;
    background-color: rgb(var(--rgb-dark-green));
    min-height: 280px;
    border-radius: 10px;

    @media screen and (max-width: 768px) { align-items: center; }
    @media screen and (max-width: 500px) { min-height: 230px; }

    &__flex {
        display: flex;
        width: 100%;

        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
        }
    }

    // Content
    &__content-item {
        flex: 1;
        align-self: center;
    }

    &__content {
        padding: var(--y-70) var(--x-60) var(--y-70) var(--x-120);
    }

    &__suptitle-container {
        margin-bottom: 15px;
    }

    &__suptitle {
        font-family: var(--ff);
        font-size: var(--fz-15);
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
    }

    &__date {
        font-weight: 600;
        margin-right: 24px;
        position: relative;

        &::before {
            content: "";
            width: 4px;
            height: 4px;
            position: absolute;
            top: calc(50% - (4px / 2));
            right: calc(0px - ((24px / 2) + (4px / 2)));
            border-radius: 50%;
            background-color: rgb(var(--rgb-white));
        }
    }

    // Image
    &__picture-item {
        width: min(650px, 45%);

        @media screen and (max-width: 1500px) { width: min(556px, 45%); }
        @media screen and (max-width: 768px) { width: 100%; }
    }

    &__picture-container {
        position: relative;
        border-bottom-right-radius: 10px;
        overflow: hidden;

        @media screen and (min-width: 769px) {
            height: cal        c(100% - var(--y-60));
            margin-top: var(--y-60);
        }
        @media screen and (max-width: 768px) {
            border-bottom-left-radius: 10px;
        }
    }

    &__picture {
        @extend .o-picture;
        padding-top: 53%;
            height: 100%;

        @media screen and (max-width: 768px) { padding-top: min(53%, 250px); }
    }

    &__img {
        @media screen and (min-width: 769px) { border-top-left-radius: 10p    x; }
    }

    &__tooltip-container {
        position: absolute;
        top: 9px;
        right: 12px;
        z-index: 1;
    }

    // Filters
    &__filters {
        display: flex;
        align-items: center;
        padding: var(--y-70) var(--x-120) var(--y-70) 0;
        align-self: center;
        min-width: 0;

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 0 var(--x-120) var(--y-70);
        }
    }

    &__select-container {
        flex: 1;
        min-width: 0;
        max-width: 300px;

        .c-select-field .tail-select {
            max-width: 100%;
        }
    }

    &__calendar-button-container {
        margin-left: 10px;
    }

    &__calendar-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 51px;
        height: 51px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgb(var(--rgb-light-purple));
            border-radius: 10px;
        }
    }

    &__calendar-svg {
        width: 20px;
        height: 20px;
        position: relative;
        fill: rgb(var(--rgb-dark-green));
        transition: fill 300ms;
    }

    &__calendar-button:hover &__calendar-svg {
        fill: rgb(var(--rgb-green));
    }

    &:has(&__filters) &__content {
        @media screen and (max-width: 768px) { padding-bottom: var(--y-40); }
    }

    // Search
    &:has(&__search-field-container) {
        flex-direction: column;
    }

    &:has(&__search-field-container) &__content {
        padding-top: var(--y-100);
        padding-bottom: var(--y-40);
    }

    &:has(&__search-field-container) &__filters {
        padding-top: var(--y-100);
        padding-bottom: var(--y-40);

        @media screen and (max-width: 768px) {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    &__search-field-container {
        width: 100%;
        padding: 0 var(--x-120) var(--y-40 );
    }
}
