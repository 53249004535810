.c-news-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: var(--x-30);

    @media screen and (max-width: 1250px) { grid-template-columns: 1fr 1fr; }
    @media screen and (max-width: 768px) { grid-template-columns: 1fr; gap: 15px; }

    &__item {
        grid-column: span 1;
    }
}