.c-menu-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 68px;
    height: 42px;
    transition: background-color 300ms;

    @media screen and (max-width: 380px) {
        width: 60px;
        height: 38px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid rgba(var(--rgb-dark-green), 0.2);
        border-radius: 31px;
        transition: background-color 300ms, border-color 300ms;
    }

    &:hover::before {
        border-color: rgb(var(--rgb-green));
        background-color: rgb(var(--rgb-green));
    }

    &__lines {
        position: relative;
        width: 16px;
        height: 13px;
    }

    &__line {
        display: block;
        position: absolute;
        width: 100%;
        height: 1.5px;
        left: 0%;
        background-color: rgb(var(--rgb-dark-green));
        transform: rotate(0deg);
        transition: 300ms;
        will-change: transform;

        &--1 {
            top: 0px;
        }

        &--2,
        &--3 {
            top: calc(50% - 0.5px);
        }

        &--4 {
            bottom: 0px;
        }    
    }

    &:hover &__line {
        background-color: rgb(var(--rgb-white));
    }
}
