@use "../../objects/picture";
@use "../buttons/button";

.c-featured-card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 400px;
    height: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    flex-wrap: wrap;

    &__content-item {
        display: flex;
        align-items: center;
        flex: 1;
        width: 100%;
        order: 2;
        background-color: rgb(var(--rgb-dark-green));
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: var(--y-30) var(--x-40) var(--y-40) var(--x-40);
    }

    &__tag-container {
        margin-bottom: var(--y-20);
        position: absolute;
        top: 18px;
        left: 20px;
        z-index: 1;
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-28);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__subtitle {
        display: block;
        margin-top: 17px;
        font-family: var(--ff);
        font-size: var(--fz-17);
        line-height: 1.5em;
        color: rgb(var(--rgb-white));
    }

    &__button-container {
        margin-top: var(--y-40);
        justify-content: flex-end;
    }

    &:hover {
        @include button.hover('style-5');
    }

    &__picture-item {
        width: min(700px, 50%);
        order: 1;
        width: 100%;
    }

    &__picture-container {
        height: 100%;
    }

    &__picture {
        @extend .o-picture;
        height: 100%;
        padding-top: min(53%, 250px);
    }

}
