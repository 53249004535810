.o-x {
    position: relative;
    will-change: transform;

    &::before,
    &::after {
        content: "";
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
    }
    
    &::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    
    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}