.c-overlay-mobile-menu {
    --c-overlay-mobile-menu-width: 400px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 29;

    @media screen and (max-width: 700px) {
        --c-overlay-mobile-menu-width: 370px;
    }

    @media screen and (max-width: 500px) {
        --c-overlay-mobile-menu-width: 100%;
    }

    &__background {
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 1000ms var(--in-out-quart);
        z-index: -1;
        cursor: default;
    }

    &__container {
        display: flex;
        width: var(--c-overlay-mobile-menu-width);
        height: calc(var(--vh, 1vh) * 100);
        padding-top: calc(5px + var(--header-height) + var(--alerts-height));
        position: absolute;
        top: 0;
        left: calc(0px - var(--c-overlay-mobile-menu-width));
        right: 100%;
        overflow: hidden;
        background-color: rgb(var(--rgb-light-purple));
        transition-property: right, left, padding-top;
        transition-duration: 1000ms, 1000ms, var(--alerts-transition-duration);
        transition-timing-function: var(--in-out-quart), var(--in-out-quart), var(--alerts-transition-timing-function);

        @media screen and (max-width: 500px) { right: 0px; width: calc(var(--c-overlay-mobile-menu-width) - 40px); }
        @media screen and (max-width: 350px) { right: 0px; width: calc(var(--c-overlay-mobile-menu-width) - 20px); }
    }

    &__scrollbar-container {
        width: 100%;
        padding-top: calc(var(--c-header-height) + 30px);
    }

    &__scrollbar {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__scrollbar {
    }

    &__content {
        opacity: 0;
        transform: translate3d(-100px, 0, 0);
        transition-property: opacity, transform;
        transition-duration: 1000ms;
        transition-timing-function: var(--out-quart);
        transition-delay: 200ms;
    }

    &__interfaces {
        position: relative;
        transition-property: height;
        transition-duration: 1000ms;
        transition-timing-function: var(--in-out-quart);
    }

    &__interface-container {
        width: 100%;
        height: 0px;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        transition-property: height;
        transition-duration: 1ms;
        transition-delay: 1000ms;
    }
    
    &__interface-container:has(.is-active) {
        transition-delay: 0ms;
        height: 100%;
    }

    &__interface {
        width: 100%;
        padding: 0 var(--x-40) 40px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;

        &.is-active {
            opacity: 1;
            transform: none;
            pointer-events: all;
        }

        &.is-on-the-right {
            transform: translate3d(150%, 0, 0);
        }

        &.is-on-the-left {
            transform: translate3d(-150%, 0, 0);
        }

        &.has-animation {
            transition-property: opacity, transform, pointer-events;
            transition-duration: 1000ms;
            transition-timing-function: var(--in-out-quart);
            transition-delay: 0ms, 0ms, 1000ms;
        }
    }

    &__big-link-container:not(:last-child) {
        margin-bottom: 15px;
    }

    &__big-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: min(400px, 100%);
    }

    &__big-link-text {
        font-family: var(--ff);
        font-size: var(--fz-30);
        font-weight: 600;
        line-height: 1.4em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-green));
        }
    }

    &__big-link-svg {
        flex-shrink: 0;
        position: relative;
        width: 16px;
        height: 12px;
        stroke-width: 1.5px;
        stroke: rgb(var(--rgb-green));
    }

    &__back-container {
        margin-bottom: 10px;
        max-width: 400px;
    }

    &__back-link {
        display: flex;
        align-items: center;
    }

    &__back-svg {
        flex-shrink: 0;
        margin-right: 15px;
        position: relative;
        width: 10px;
        height: 10px;
        stroke-width: 1.5px;
        transform: rotate(180deg);
        stroke: rgb(var(--rgb-dark-green));
        transition: stroke 300ms;
    }

    &__back-link:hover &__back-svg {
        stroke: rgb(var(--rgb-green));
    }

    &__back-text {
        font-family: var(--ff);
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.4em;
        color: rgb(var(--rgb-dark-green));
        text-transform: uppercase;
        letter-spacing: 1.2px;
        transition: color 300ms;
    }

    &__back-link:hover &__back-text {
        color: rgb(var(--rgb-green));
    }

    &__title {
        display: block;
        font-family: var(--ff);
        font-size: var(--fz-30);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-green));
    }

    &__column:first-child {
        margin-top: 38px;
    }

    &__column-title {
        display: block;
        padding: 14px 20px;
        border: 1px solid rgba(var(--rgb-dark-green), 0.2);
        border-radius: 10px;
        font-family: var(--ff);
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        text-transform: uppercase;
        letter-spacing: 1.2px;
    }

    &__links-container {
        margin-top: 24px;
        padding-bottom: 40px;
    }

    &__link-container {
        padding: 0 21px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    
    &__link {
        font-family: var(--ff);
        font-size: var(--fz-17);
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        transition: color 300ms;

        &:hover {
            color: rgb(var(--rgb-green));
        }
    }
}

.show-overlay-mobile-menu {
    .c-overlay-mobile-menu {
        pointer-events: all;
        visibility: visible;
    }
}

.show-overlay-mobile-menu:not(.closing-overlay-mobile-menu) {
    --alerts-height: 0px;

    .c-overlay-mobile-menu {
        &__container {
            right: calc(100% - var(--c-overlay-mobile-menu-width));
            left: 0%;

            @media screen and (max-width: 500px) { right: 0%; }
        }

        &__content {
            opacity: 1;
            transform: none;
            transition-delay: 500ms;
        }

        &__background {
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }

    .c-menu-button {
        &::before {
            background-color: rgb(var(--rgb-white));
            border: 1px solid rgba(var(--rgb-dark-green), 0);
        }
        
        &:hover::before {
            background-color: rgb(var(--rgb-green));
        }

        &__line--1 { width: 0%; top: calc(50% - 1px); left: 50%; opacity: 0; }
        &__line--2 { transform: rotate(45deg); width: 100%; }
        &__line--3 { transform: rotate(-45deg); width: 100%; }
        &__line--4 { width: 0%; bottom: calc(50% - 1px); left: 50%; opacity: 0; }
    }
}
