.c-events-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    gap: var(--y-60) var(--x-30);

    @media screen and (max-width: 768px) { grid-template-columns: 1fr; }
    @media screen and (max-width: 500px) { gap: 42px var(--x-30); }

    &__item {
        grid-column: span 1;
    }
}