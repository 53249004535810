@use 'sass:math';

:root {
    // Générales
    --calendar-header-background-color: transparent;
    --calendar-header-color: rgb(var(--rgb-dark-green));

    --calendar-main-background-color: rgb(var(--rgb-white));
    --calendar-main-color: rgb(var(--rgb-dark-green));
    --calendar-main-day-cell-size: 4.6rem;
    @media screen and (max-width: 1024px) { --calendar-main-day-cell-size: 4rem; }
    @media screen and (max-width: 600px) { --calendar-main-day-cell-size: 3.6rem; }
    @media screen and (max-width: 400px) { --calendar-main-day-cell-size: 3.4rem; }
    --calendar-main-month-year-cell-size: calc(var(--calendar-main-day-cell-size) * 1.7);

    // Dates des mois précédents ou suivant (.prev, .next)
    --calendar-day-previous-or-next-color: rgba(var(--rgb-dark-green), 0.6);
    --calendar-day-previous-or-next-background-color: transparent;
    --calendar-day-previous-or-next-dot-or-underline-background-color: rgba(var(--rgb-green), 0.6);
    --calendar-month-year-previous-or-next-color: rgba(var(--rgb-dark-green), 0.6);


    // Date avec événement (:not(.disabled))
    --calendar-day-color: rgb(var(--rgb-dark-green));
    --calendar-day-background-color: transparent;
    --calendar-day-dot-or-underline-background-color: rgb(var(--rgb-green));
    --calendar-month-year-color: rgb(var(--rgb-dark-green));

    --calendar-day-color-hover: rgb(var(--rgb-dark-green));
    --calendar-day-background-color-hover: rgb(var(--rgb-light-purple));
    --calendar-day-dot-or-underline-background-color-hover: rgb(var(--rgb-green));
    --calendar-month-year-color-hover: rgb(var(--rgb-green));


    // Date d'aujourd'hui
    --calendar-today-color: rgb(var(--rgb-dark-green));
    --calendar-today-background-color: transparent;
    --calendar-today-border-color: rgba(var(--rgb-dark-green), 0.2);
    --calendar-today-dot-or-underline-background-color: rgb(var(--rgb-green));

    --calendar-today-color-hover: rgb(var(--rgb-dark-green));
    --calendar-today-background-color-hover: rgb(var(--rgb-light-purple));
    --calendar-today-border-color-hover: rgb(var(--rgb-light-purple));
    --calendar-today-dot-or-underline-background-color-hover: rgb(var(--rgb-green));


    // Date sélectionnée
    --calendar-day-active-color: rgb(var(--rgb-white));
    --calendar-day-active-background-color: rgb(var(--rgb-green));
    --calendar-day-active-dot-or-underline-background-color: rgb(var(--rgb-white));

    --calendar-day-active-color-hover: rgb(var(--rgb-white));
    --calendar-day-active-background-color-hover: rgb(var(--rgb-green));
    --calendar-day-active-dot-or-underline-background-color-hover: rgb(var(--rgb-white));
}

// Calendrier en dropdown dans champ date
.datepicker-dropdown {
    position: absolute;
    top: calc(100% + 15px) !important;
    left: 0 !important;
    width: 350px;
    background-color: white;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 300ms, visibility 0ms ease 300ms;
    z-index: 100;
    border-radius: 10px;

    @media screen and (max-width: 425px) {
        width: 100%;
    }

    &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition: opacity 300ms;
    }

    .datepicker-picker .datepicker-header {
        // @media screen and (max-width: 425px) {
        //     padding-left: 10px;
        //     padding-right: 10px;
        // }
    }

    .datepicker-picker .datepicker-main {
        --calendar-main-background-color: white;

        // @media screen and (max-width: 425px) {
        //     padding-top: 28px;
        //     padding-bottom: 18px;
        //     padding-left: 10px;
        //     padding-right: 10px;
        // }
    }

    .datepicker-picker .day::after {
        display: none;
    }
}

.calendar-zone {
    max-width: 430px;

    @media screen and (max-width: 1024px) { width: 380px; }
    @media screen and (max-width: 600px) { width: 340px; }
    @media screen and (max-width: 425px) { width: 100%; }
}

.datepicker-picker {
    display: inline-block;

    span {
        display: block;
        flex: 1;
        border: 0;
        cursor: default;
        text-align: center;
        -webkit-touch-callout: none;
        user-select: none;
    }

    .datepicker-header {
        background-color: var(--calendar-header-background-color);
        border-bottom: 1px solid rgba(var(--rgb-dark-green), 0.2);
        padding: 24px 0;
    }

    .datepicker-main {
        background-color: var(--calendar-main-background-color);
        padding: 34px 14px 30px;

        @media screen and (max-width: 1024px) { padding: 30px 8px; }
        @media screen and (max-width: 600px) { padding: 30px 0; }
    }
}

.datepicker-controls {
    display: flex;

    .button {
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 0;
        cursor: pointer;
        white-space: nowrap;
        font-family: var(--ff);
        font-size: var(--fz-nus-25);
        font-weight: 600;
        color: var(--calendar-header-color);

        @media screen and (max-width: 1024px) { font-size: var(--fz-22); }
        @media screen and (max-width: 600px) { font-size: var(--fz-20); }

        &:focus,
        &:active {
            outline: none;
        }

        &[disabled] {
            cursor: not-allowed;
        }
    }

    .view-switch {
        flex: auto;
    }

    .prev-btn,
    .next-btn {

        >svg {
            width: 12px;
            height: 12px;
            stroke: rgb(var(--rgb-dark-green));
            stroke-width: 2px;
            transition: stroke 300ms;
        }
        
        &:hover > svg {
            stroke: rgb(var(--rgb-green));
        }

        &.disabled {
            visibility: hidden;
        }
    }

    .prev-btn>svg {
        transform: rotate(-180deg);
    }
}

.datepicker-view {
    display: flex;

    .days-of-week {
        display: flex;
        margin-bottom: 10px;
    }

    .dow {
        flex-basis: percentage(math.div(1, 7));
        font-size: var(--fz-17);
        font-weight: 700;
        color: var(--calendar-main-color);

        @media screen and (max-width: 1024px) { font-size: var(--fz-15); }
        @media screen and (max-width: 600px) { font-size: 1.3rem; }
    }
}

.datepicker-grid {
    display: flex;
    flex-wrap: wrap;
}

.datepicker-cell {
    height: var(--calendar-main-day-cell-size);
    line-height: var(--calendar-main-day-cell-size);
    margin-top: 12px;

    &.day {
        position: relative;
        font-size: var(--fz-17);
        font-weight: 500;
        color: var(--calendar-main-color);
        z-index: 1;
        transition: color 300ms;

        @media screen and (max-width: 1024px) { font-size: var(--fz-15); }
        @media screen and (max-width: 600px) { font-size: 1.3rem; }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: var(--calendar-main-day-cell-size);
            height: var(--calendar-main-day-cell-size);
            background-color: transparent;
            border-radius: 10px;
            transition: background-color 0.3s;
            box-sizing: border-box;
            z-index: -1;
        }
        
        &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 8px;
            transform: translateX(-50%);
            width: 4px;
            height: 4px;
            background-color: transparent;
            border-radius: 50%;
            transition: background-color 0.3s;
            z-index: 1;
            box-sizing: border-box;

            @media screen and (max-width: 1024px) { bottom: 6px; }
        }
    }

    &.month,
    &.year,
    &.decade {
        font-size: var(--fz-16);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .1em;
        color: var(--calendar-main-color);
    }

    &.disabled {
        cursor: default;

        &::after {
            display: none;
        }
    }

    // Date avec événement (:not(.disabled))
    &:not(.disabled) {
        cursor: pointer;

        &::after {
            background-color: var(--calendar-day-dot-or-underline-background-color);
        }

        &:hover {
            &::before {
                background-color: var(--calendar-day-background-color-hover);
            }
        }
    }

    // Date d'aujourd'hui
    &.day.today:not(.selected) {
        color: var(--calendar-today-color);

        &::before {
            background-color: var(--calendar-today-background-color);
            border: 1px solid var(--calendar-today-border-color);
        }

        &::after {
            background-color: var(--calendar-today-dot-or-underline-background-color);
        }

        &:hover:not(.disabled) {
            color: var(--calendar-today-color-hover);

            &::before {
                background-color: var(--calendar-today-background-color-hover);
                border: 1px solid var(--calendar-today-border-color-hover);
            }

            &::after {
                background-color: var(--calendar-today-dot-or-underline-background-color-hover);
            }
        }
    }

    // Date sélectionnée
    &.day.selected {
        color: var(--calendar-day-active-color);

        &::before {
            background-color: var(--calendar-day-active-background-color);
        }

        &::after {
            background-color: var(--calendar-day-active-dot-or-underline-background-color);
        }

        &:hover {
            color: var(--calendar-day-active-color-hover);

            &::before {
                background-color: var(--calendar-day-active-background-color-hover);
            }

            &::after {
                background-color: var(--calendar-day-active-dot-or-underline-background-color-hover);
            }
        }
    }

    // Dates des mois précédents ou suivant (.prev, .next)
    &.day.prev,
    &.day.next {
        color: var(--calendar-day-previous-or-next-color);

        &::before {
            background-color: var(--calendar-day-previous-or-next-background-color);
        }

        &::after {
            background-color: var(--calendar-day-previous-or-next-dot-or-underline-background-color);
        }
    }

    &.month,
    &.year,
    &.decade {

        &.prev,
        &.next {
            color: var(--calendar-month-year-previous-or-next-color);
        }

        &.selected,
        &:hover {
            color: var(--calendar-month-year-color-hover);
        }
    }
}

.datepicker-view {
    .days .datepicker-cell {
        flex-basis: percentage(math.div(1, 7));
    }

    &.months .datepicker-cell,
    &.years .datepicker-cell,
    &.decades .datepicker-cell {
        flex-basis: 25%;
        height: var(--calendar-main-month-year-cell-size);
        line-height: var(--calendar-main-month-year-cell-size);
    }
}

.datepicker-dropdown {
    padding: 10px 30px 0;
    width: 380px;

    --calendar-main-day-cell-size: 3.6rem;

    @media screen and (max-width: 450px) {
        width: 100%;
        right: 0;
    }

    .datepicker-main {
        padding: 30px 0;
    }

    .datepicker-controls .button {
        font-size: var(--fz-20);
    }

    .datepicker-view .dow {
        font-size: 1.4rem; 
    }
    .datepicker-cell {
        margin-top: 4px;
    }

    .datepicker-cell.day {
        font-size: 1.4rem;

        &::after {
            bottom: 6px;
        }
    }
}