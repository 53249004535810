.c-contact-infos {
    &__title {
        font-family: var(--ff);
        font-size: var(--fz-30);
        font-weight: 600;
        line-height: 1.3em;
        color: rgb(var(--rgb-dark-green));
    }

    &__infos {
        margin: var(--y-20) 0 var(--y-30);
    }

    &__info {
        display: flex;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid rgba(var(--rgb-dark-green), 0.2);
    }
    
    &__svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        margin-right: 14px;
        border-radius: 10px;
        background-color: rgb(var(--rgb-light-purple));
    }

    &__svg {
        width: 18px;
        height: 18px;
        fill: rgb(var(--rgb-dark-green));
    }

    &__text {
        font-family: var(--ff);
        font-size: var(--fz-15);
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));

        @media screen and (max-width: 360px) { font-size: var(--fz-14); }
    }

    .c-underlined-link {
        font-size: var(--fz-15);

        @media screen and (max-width: 360px) { font-size: var(--fz-14); }
    }

    &__buttons {
        display: flex;
        align-items: center;

        @media screen and (max-width: 360px) {
            flex-direction: column;
        }
        
        .c-button {
            @media screen and (max-width: 360px) {
                width: 100%;
                justify-content: center;
            }
            &:first-child {
                margin-right: 10px;

                @media screen and (max-width: 360px) { margin: 0 0 10px 0; }
            }
        }
    }
}