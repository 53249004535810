@use "../objects/picture";
@use "buttons/button";

.c-showcase {
    &__flex {
        display: flex;
        align-items: stretch;
        min-height: 400px;
        position: relative;
        background-color: rgb(var(--rgb-dark-green));
        border-radius: 10px;
        overflow: hidden;

        @media screen and (max-width: 768px) { flex-wrap: wrap; }
    }

    &__content-item {
        display: flex;
        align-items: center;
        flex: 1;

        @media screen and (max-width: 768px) {
            width: 100%;
            order: 2;
        }
    }

    &__content {
        padding: var(--y-70) var(--x-40) var(--y-70) var(--x-80);
    }

    &__tag-container {
        margin-bottom: var(--y-20);

        @media screen and (max-width: 768px) {
            position: absolute;
            top: 18px;
            left: 20px;
            z-index: 1;
        }
    }

    &__title {
        font-family: var(--ff);
        font-size: var(--fz-32);
        font-weight: 500;
        line-height: 1.3em;
        color: rgb(var(--rgb-white));
    }

    &__button-container {
        margin-top: var(--y-30);
    }

    &:hover {
        @include button.hover('style-5');
    }

    &__picture-item {
        width: min(700px, 50%);

        @media screen and (max-width: 768px) {
            order: 1;
            width: 100%;
        }
    }

    &__picture-container {
        height: 100%;
    }

    &__picture {
        @extend .o-picture;
        padding-top: 53%;
        height: 100%;

        @media screen and (max-width: 768px) { padding-top: min(53%, 250px); }
    }

}
