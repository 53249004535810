@use "../../base/typography";
@use "../../objects/x";

.c-overlay-popup {
    --overlay-popup-width: 520px;
    --overlay-popup-padding: 100px 40px 80px;

    @media screen and (max-width: 1200px) {
        --overlay-popup-padding: 100px 30px 80px;
    }
    @media screen and (max-width: 1024px) {
        --overlay-popup-padding: 70px 22px 50px;
    }
}

.c-overlay-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 120;

    // Container
    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -40%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: var(--overlay-popup-width);
        max-width: calc(100% - (var(--x-40) * 2));
        max-height: calc(var(--vh, 1vh) * 90);
        padding: var(--overlay-popup-padding);
        background-color: rgb(var(--rgb-white));
        border-radius: 21px;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        transition: transform 800ms var(--in-out-quart), opacity 800ms var(--in-out-quart);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__date {
        display: block;
        margin-bottom: 5px;
        font-family: var(--ff);
        font-size: var(--fz-15);
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
    }

    &__title {
        @extend .c-medium-title;
        text-align: center;
        margin-bottom: 20px;
    }

    &__close-button {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        padding: var(--y-25) var(--x-25) 0 0;

        @media screen and (max-width: 600px) { padding: 12px 12px 0 0; }
    }

    &__close-text {
        margin-right: 10px;
        font-family: var(--ff);
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5em;
        color: rgb(var(--rgb-dark-green));
        text-transform: uppercase;
        letter-spacing: 1px;

        @media screen and (max-width: 600px) { display: none; }
    }

    &__close-x-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        border: 1px solid rgba(var(--rgb-dark-green), 0.2);
        border-radius: 30px;
        transition: background-color 300ms, border-color 300ms;
    }

    &__close-button:hover &__close-x-container {
        background-color: rgb(var(--rgb-green));
        border: 1px solid rgba(var(--rgb-dark-green), 0);
    }

    &__close-x {
        @extend .o-x;
        width: 14px;
        height: 14px;

        &::before,
        &::after {
            height: 1.5px;
            background-color: rgb(var(--rgb-dark-green));
            transition: background-color 300ms;
        }
    }

    &__close-button:hover &__close-x::before,
    &__close-button:hover &__close-x::after {
        background-color: rgb(var(--rgb-white));
    }

    &__description {
        @extend .c-paragraph;
        text-align: center;
    }

    &__button {
        margin-top: var(--y-40);
    }

    // Background
    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-black), 0);
        transition: background-color 500ms;
        z-index: -1;
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-popup {
    .c-overlay-popup {
        visibility: visible;
        pointer-events: all;

        &__container {
            opacity: 1;
            transform: translate(-50%, -50%);
            pointer-events: all;
        }

        &__background{
            background-color: rgba(var(--rgb-black), 0.5);
        }
    }
}


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-popup.closing-overlay-popup {
    .c-overlay-popup {
        &__container {
            opacity: 0;
            transform: translate(-50%, -40%);
        }

        &__background{
            background-color: rgba(var(--rgb-black-charcoal), 0);
        }
    }
}
