/*
|--------------------------------------------------------------------------
| Les variables de easing
|--------------------------------------------------------------------------
|
| Déclaration des variables de easing du projet. https://easings.net/

|
*/

:root {
    --out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
    --out-quart: cubic-bezier(0.25, 1, 0.5, 1);
    --in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);
    --in-out-quart: cubic-bezier(0.76, 0, 0.24, 1);
}
