.u-text {
    &-65 {
        --u-text-65: var(--fz-65);
        font-size: var(--u-text-65);
    }
    &-60 {
        --u-text-60: var(--fz-60);
        font-size: var(--u-text-60);
    }
    &-55 {
        --u-text-55: var(--fz-55);
        font-size: var(--u-text-55);
    }
    &-50 {
        --u-text-50: var(--fz-50);
        font-size: var(--u-text-50);
    }
}
